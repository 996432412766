import { Component } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-404',
  template: `
  <p-card>
  <p-header class="title">{{title}}</p-header>
  <div>{{intro}}</div>
  </p-card>
  `
})
export class PageNotFoundComponent {
  title!:string;
  intro!:string;
  header!:string;
  constructor(public _translate: TranslateService) {
    this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {

      const status404 = event.translations["404"];
      this.setvalues(status404);
    });
    this.GetLanguageData();
   }

   private setvalues(status404: any) {
    this.title = status404['title'];
    this.header = status404['header'];
    this.intro = status404['intro'];
  }

  private GetLanguageData() {
    const status404 = this._translate.instant("404");
    this.setvalues(status404);
  }


}
