import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/loader/loader.service';

import { KeuzedeelTypen } from 'src/app/helper/keuzedelen-type';

import { QueryOptions } from 'src/app/core/services/query.options';

import { Subject } from 'rxjs';

import { IDropdown } from 'src/app/core/model/dropdown.interface';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ILocatie } from 'src/app/core/model/locatie.interface';
import { ISBB } from 'src/app/core/model/sbb.interface';
import { LocatieService } from 'src/app/core/services/api/locatie.service';
import { FragmentService } from 'src/app/core/services/api/fragment.service';
import { IFragment } from 'src/app/core/model/fragment.interface';
import { IKeuzedeel } from 'src/app/core/model/keuzedeel.interface';
import { ITable } from 'src/app/core/model/table.interface';
import { SSBService } from 'src/app/core/services/api/ssb.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';


@Component({
  selector: 'old-tile-table',
  templateUrl: './tile-table.component.html',
  styleUrls: ['./tile-table.component.css']
})
export class TileTableComponent implements OnInit {
  id:number=0;
  fragmentName:string="";
  content:string="";
  locatieOption: IDropdown[]=[];
    // Query opties for api call
    o:QueryOptions= new QueryOptions;
    locatieDataTemp: any[]=[];
    sbblisttemp: any[]=[];
    sbblist: any[]=[];
    arraySBB: ISBB[]=[];
    starts: any;
    showTable:boolean=false;
    ends: any;
    gegevenin: any;
    keuze: IDropdown[]=[];
    tableData : ITable[]=[];
    cols!: { field: string; header: string; }[];
    destroy$: Subject<boolean> = new Subject<boolean>();
    loading: boolean=false;
  NAME: any;
  LOCATION: any;
  START: any;
  CHOOSE: any;
  CHOOSEBY: any;
  ALL: any;
  ALLDATA: any;
  ONLYSELECTION: any;
    constructor(public ref: DynamicDialogRef,
      private _loaderService: LoaderService,
      private _translate: TranslateService,
      private _sbbService:SSBService,
      private _locatieService: LocatieService,
      public config: DynamicDialogConfig, private fragmentServices:FragmentService,) {
        this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
          const countinfo = event.translations["TILETABLE"];
          const app = event.translations["APP"];
          this.setvalues(app, countinfo);
        });
        this.GetLanguageData();
        this._loaderService.show();
      }
    ngOnInit() {
      this.loading = true;
      this.starts=KeuzedeelTypen.GetStartEnd();
      this.ends=KeuzedeelTypen.GetStartEnd();
      this.gegevenin=KeuzedeelTypen.GetGegevenIn();
      this.keuze=KeuzedeelTypen.GetKeuze();
      this.cols = [
        { field: 'title', header: this.NAME },
        { field: 'locaties', header:this.LOCATION },
        { field: 'start', header: this.START },
        { field: 'keuzetype', header: this.CHOOSEBY },
        { field: 'keuze', header: this.CHOOSE }
      ];
      this.o.fields = "id,code,name,level";
      this._locatieService.list(this.o).subscribe((data: ILocatie[]) => {
        if (data != null) {
        this.locatieOption=[];
        this.locatieDataTemp=[]
        data.forEach( (locatie:ILocatie)=>{
            if(locatie.level==1)
            this.locatieDataTemp.push(
            {name:  locatie.name, code: locatie.id.toString()}
          )});
          this.locatieOption= this.locatieDataTemp;
        }
      });
      this.o.fields="id,title,sbu,code";
      this._sbbService.list(this.o).subscribe((result: ISBB[]) => {
        if (result != null) {
          this.sbblisttemp=[];
          this.sbblist=[];
          this.arraySBB = result;
          result.forEach(sbb => {
            let name =`${sbb.code}  ${sbb.title}`;
           this.sbblisttemp.push({label:name,value:sbb.code});
          });
          this.sbblist =  this.sbblisttemp;
          this.destroy$.next(true);
        }
      });
       this.destroy$.subscribe((b)=>{ this.getTitleData()})
        this.fragmentName= this.config.data.fragmentName;
    }
    private getTitleData() {
      this.fragmentServices.read(this.config.data.id).subscribe((result: IFragment[]) => {
        if (result != null) {
          result.forEach(element => {
            if (!element.content.startsWith("[{")) {
              this.content = this.content + element.content;
              this.showTable = false;
            }
            else {
              this.showTable = true;
              let o: IKeuzedeel[] = JSON.parse(element.content) || [];
              // Id omzetten naar tekst
              o.forEach((j: IKeuzedeel) => {
                let title = this.GetKeuzeTitleByCode(j.keuzdeelid);
                let locaties = this.GetKeuzeLocationByCode(j.locatie);
                let start = KeuzedeelTypen.GetStartByCodes(j.start);
                let keuzetype = KeuzedeelTypen.GetKeuzeInByCodes(j.gegevenin);
                let keuze = KeuzedeelTypen.GetKeuzeNameByCode(j.type);
                let td: ITable = { title: title, locaties: locaties, start: start, keuze: keuze, keuzetype: keuzetype };
                this.tableData.push(td);
              });
              this.loading = false;
            }
            // Keuzedelen  string json object omzetten naar keuzdelen object
          });
          this._loaderService.hide();
        }
      });
    }

      /* Omzetten van id naar tekst in tabel */
      public GetKeuzeNameByCode(code:number|undefined)
      {
        if(code==undefined)
          return "";
         return  this.keuze.filter(l=>{return l.code==code.toString()})[0].name;
      }
      public GetKeuzeTitleByCode(id:number|undefined)
      {
        if(this.arraySBB==undefined)
          return "";
       return this.arraySBB.filter((k)=>{return k.id== id}).map((o) =>  {return o.code +' - '+ o.title}).toString()
      }
      public GetKeuzeLocationByCode(code:number[]|undefined)
      {
        if(code==undefined)
          return "";
          return this.locatieOption.filter( (o) => {return code.includes(+o.code)} ).map(o => o.name)
          .reduce((g:string, name:string) => {
           g +=`${name} \n`;
           return g;
         }, "");
      }
      /* Einde omzetten van id naar tekst in tabel */

      private setvalues(app:any, tiletable: any) {
 
        this.ALL = app['ALL'];
        this.ALLDATA = app['ALLDATA'];
        this.ONLYSELECTION = app['ONLYSELECTION'];
        this.NAME = tiletable['NAME'];
        this.LOCATION = tiletable['LOCATION'];
        this.START = tiletable['START'];
        this.CHOOSE = tiletable['CHOOSE'];
        this.CHOOSEBY = tiletable['CHOOSEBY'];
    
      }
      private GetLanguageData() {
        const file = this._translate.instant('TILETABLE');
        const app = this._translate.instant('APP');
        this.setvalues(app, file);
      }
  }

