import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ErrorDialogComponent } from 'src/app/components/error/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ErrordialogService {

  constructor( public _dialogService: DialogService) { }
  openDialog(data:any): void {
      const dialogRef = this._dialogService.open(ErrorDialogComponent, {
          width: '400px',
          height:'300px',
          data: data
      });
  }
}
