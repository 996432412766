import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource.service';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { AlertService } from '../alert.service';

@Injectable()
export class OERService extends ResourceService<any> {
  constructor(
    httpClient: HttpClient,
    _alertServices:AlertService,
    private configService: ConfigService) {
    super(
      httpClient,
      configService.apiAzure,
      _alertServices,
      'oer'
      );
  }
}
