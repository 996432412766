export const environment = {
  title:'Onderwijsleidraad - Nova College',
  production: true,
  installpwa: true,
  domain:'onderwijsleidraad.novacollege.nl',
  domainapi:'onderwijsleidraad-v2-api-rocnova.azurewebsites.net',
  domainapiAppPath:'',
  apiVersion:'1.0',
  signalrHubUrl:'/datapipe',
  endpoints: {
    "eef664c3-1195-4408-888c-de4980cf78b4": "https://novaportal.onmicrosoft.com/eef664c3-1195-4408-888c-de4980cf78b4"
  },
  appInsights: {
    instrumentationKey: 'c3d3944e-639a-44ba-9b0f-abbcbad02994'
  },
  azureStorageContainerBase:'uploadimages',
  config: {
     VAPID_PUBLIC_KEY:'BFm1N5fyIo-xbDufI_DwoKnz7IXdY3oB2Q-ryrIiGAmxvWAZB9Won_xYnYjACstfHDdDNlfEYJKgaNauRdM0T9k',
     apiEndpoint:'https://novaportal.onmicrosoft.com/eef664c3-1195-4408-888c-de4980cf78b4'
  },
    regex:{
      element: '(2[3|5]{1}\\d\\d\\d)?(\\-)?(((([BO|BB|CO|CB]{2}[L])|([O][VO|DT]{2})(_DT)?))?([A-Z]{4}|algemeen))?(\\-.{2,10})$',
      fragment:  '([a-zA-Z]{4}\\-)?((1|2)[0-9](1|2)[0-9]\\-)?((([BO|BB|CO|CB]{2}[L])|([O][VO|DT]{2})(_DT)?)\\-)?(.{2,10})$',
      onderwijsleidraad:'^[23\\d\\d\\d]+\\-(1|2)[0-9](1|2)[0-9]\\-(([BO|BB|CO|CB]{2}[L])|([O][VO|DT]{2})(_DT)?)\\-[A-Z]{4}(\\-.{1,50})?$',
      titlefilter: '^.{3,30}$',
      testfilter: '^\\w*$',
      imgtype:'_((?<l>L)|(?<s>S)|(?<m>M))[W|H]\d{3,4}_',
      bodyfilter:'^.{3,100}$',
      tilenamefilter:'^(([A-Z][a-zA-Z0-9_ëé\\S]{1,15})+((\\-[a-zA-Z0-9_ëé\\s]{2,15}){1,2})?)$',
      colorfilter:'#(?:[a-f\\d]{3}){1,2}\\b|rgb\\((?:(?:\\s*0*(?:25[0-5]|2[0-4]\\d|1?\\d?\\d)\\s*,){2}\\s*0*(?:25[0-5]|2[0-4]\\d|1?\\d?\\d)|\\s*0*(?:100(?:\\.0+)?|\\d?\\d(?:\\.\\d+)?)%(?:\\s*,\\s*0*(?:100(?:\\.0+)?|\\d?\\d(?:\\.\\d+)?)%){2})\\s*\\)|hsl\\(\\s*0*(?:360|3[0-5]\\d|[12]?\\d?\\d)\\s*(?:,\\s*0*(?:100(?:\\.0+)?|\\d?\\d(?:\\.\\d+)?)%\\s*){2}\\)|(?:rgba\\((?:(?:\\s*0*(?:25[0-5]|2[0-4]\\d|1?\\d?\\d)\\s*,){3}|(?:\\s*0*(?:100(?:\\.0+)?|\\d?\\d(?:\\.\\d+)?)%\\s*,){3})|hsla\\(\\s*0*(?:360|3[0-5]\\d|[12]?\\d?\\d)\\s*(?:,\\s*0*(?:100(?:\\.0+)?|\\d?\\d(?:\\.\\d+)?)%\\s*){2},)\\s*0*(?:1|0(?:\\.\\d+)?)\\s*\\)',
      urlfilter: '^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$',
      crebonrfilter:'^(25.{3})|(30.{3})$'
    },
    blobimgm:'https://onderwijsleidraadimages.blob.core.windows.net/imgm/',
    blobimgs:'https://onderwijsleidraadimages.blob.core.windows.net/imgs/',
    blobimgl:'https://onderwijsleidraadimages.blob.core.windows.net/imgl/',
    blobthumb:'https://onderwijsleidraadimages.blob.core.windows.net/thumbnails/',
    serviceWorkerScript: 'sw-master.js',
    auth: {
      clientId: "f58234a7-edeb-4408-b2e4-4119b36419a5",
      authority: "https://login.microsoftonline.com/4f3b2cbf-b16e-4223-9554-c67fbac23d69",
      validateAuthority: true,
      redirectUri: "https://onderwijsleidraad.novacollege.nl/dashboard",
      postLogoutRedirectUri: "https://onderwijsleidraad.novacollege.nl",
      navigateToLoginRequestUrl: true,
      tenantId:'4f3b2cbf-b16e-4223-9554-c67fbac23d69'
    },
    cache: {
      cacheLocation: "localStorage"
    },
    system: {
      loadFrameTimeout: 10000,
    },
    scopes: {
      loginRequest: [ "openid", "profile" ]
    },
    resources: {
      onderwijsleidraadApi: {
        resourceWs: "wss://onderwijsleidraad-v2-api-rocnova.azurewebsites.net",
        resourceUri: "https://onderwijsleidraad-v2-api-rocnova.azurewebsites.net/v1",
        resourceScope: "api://67088ac2-05ef-4be2-ac18-f35496e3b96d/user_impersonation"
      }
    },
    groups: {
      groupAdmin: "cc81be29-c14d-47a3-a821-40516c0b1c5e",
      groupInvoerder: "0e0d2ae1-78cb-40f9-82b0-b3fc3494d689",
      groupPersoneel: "31077085-40fb-43d5-a9ba-dc1e29fb766f",
      groupStudent: "4696883e-d998-4fea-aa43-4aac036c3415",
      groupGoedkeurders:"3a890721-5aed-44b0-ae4c-e61a890d15f6"
    },
    roles: {
      roleAdmin: "Admin",
      roleInvoerder: "Invoerder",
      roleGoedkeurder: "Goedkeurder",
      roleReadOnly: "ReadOnly",
      roleOpleidingsmanager: "Opleidingsmanager"
    }
};
