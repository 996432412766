import { IiOsInfo } from "../core/model/ios-info.interface";


// // Example 1: iPhone with iOS 14.4 and Safari
// let userAgent = 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 Mobile/15E148 Safari/604.1';
// let info = iOS.iOsInfo(userAgent);
// console.log(info);  // Output: { isIos: true, os16_4_1_or_higher: false, isSafari: true, deviceType: 'iphone' }

// // Example 2: iPad with iOS 15.0 and Safari
// userAgent = 'Mozilla/5.0 (iPad; CPU OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1';
// info = iOS.iOsInfo(userAgent);
// console.log(info);  // Output: { isIos: true, os16_4_1_or_higher: false, isSafari: true, deviceType: 'ipad' }

// // Example 3: Non-iOS device
// userAgent = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3';
// info = iOS.iOsInfo(userAgent);
// console.log(info);  // Output: { isIos: false, os16_4_1_or_higher: false, isSafari: false, deviceType: '' }

export class iOS{
    
  /**
   * Retrieves information about the iOS device and browser based on the user agent string.
   * @param userAgent - The user agent string.
   * @returns An object containing information about the iOS device and browser.
   */

  public static iOsInfo(userAgent:string) :IiOsInfo {
// This regular expression is used to extract information about an iOS device from a string.

// The 'device' group matches either 'ipad' or 'iphone'.
const DEVICE_TYPES = "(ipad|iphone)";

// The 'ios' group matches iOS version numbers. It supports versions 10.0 to 29.9, with either a dot or an underscore as the separator.
const IOS_VERSIONS = "(1[0-9][_|\\.][0-9][0-9]?|1[7-9]|2[0-9])";

// The 'browser' group matches the string 'safari'.
const BROWSER_TYPES = "(safari)";

// The regular expression is case-insensitive (due to the 'i' flag), so it can match strings like 'iPhone', 'IPHONE', 'iphone', etc.
const IOS_INFO_REGEX = new RegExp(`(?<device>${DEVICE_TYPES}).*os\\s(?<ios>${IOS_VERSIONS}).*(?<browser>${BROWSER_TYPES})`, "i");

    let os16_4_1_or_higher = false;
    let isIos: boolean = false;
    let isSafari: boolean = false;
    let deviceType: string = "";
    const match = userAgent.match(IOS_INFO_REGEX);
    if (match !== null) {
      for (const name in match.groups) {
        if (name === "device")
        {  isIos = true;
            deviceType = match.groups[name];
        }
        if (name === "ios") {
          const version: number = parseFloat(match.groups[name].replace("_", "."));
          if (version >= 16.4)
            os16_4_1_or_higher = true;
        }
        if (name === "browser")
          isSafari = true;
      }
    }
    return { isIos, os16_4_1_or_higher, isSafari,deviceType };
    }
}