import { Component, ChangeDetectorRef,AfterViewChecked, OnDestroy, OnInit,  Renderer2, ApplicationRef, HostListener  } from '@angular/core';
import { Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, Router, ResolveEnd } from '@angular/router';
import { Subscription,of as observableOf, Observable, interval, concat, of, timer, fromEvent} from 'rxjs';

import { AppInsightsService } from './core/services/app-insights.service';
import {  SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { TranslateService } from '@ngx-translate/core';
import { debounce, debounceTime, filter, map } from 'rxjs/operators';
import { Title, Meta,} from '@angular/platform-browser';
import { MessageService, Message } from 'primeng/api';
import { WindowRef } from './window-ref';
import { CookieService } from 'ngx-cookie-service';
import { first, tap } from 'rxjs/operators';
import { LoaderService } from './core/loader/loader.service';
import { NavItem } from './core/model/navitem.interface';
import { SignalrService } from './core/services/signalR/SignalrService';
import * as signalR from '@microsoft/signalr';
import { IiOsInfo } from './core/model/ios-info.interface';
import { iOS } from './helper/ios-info';
import { ISqlChanged } from './core/model/sqlchanged.interface';
import { ICounter } from './core/model/counter.interface';
import { TextSelectionService } from './helper/selected-text';
import {registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { environment } from 'src/environments/environment';
// const debounce = (func:any, wait:number) => {
//   let timeout: any;
//   return function executedFunction(...args:any[]) {
//     const later = () => {
//       clearTimeout(timeout);
//       func(...args);
//     };
//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//   };
// };

@Component({
  selector: 'old-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements  AfterViewChecked, OnDestroy,OnInit {
  private subscription: Subscription;
  isSpinnerVisibile$: Observable<boolean> = this._loaderService.isNavigationPending$;
  isFetching: Observable<boolean>=observableOf(false);
  title = 'Onderwijsleidraad';
  loading: boolean= false;
  isIOS:Boolean;
  messageonOff!:Message;
  message!:Message;
  messageTap2Ipad!:string;

  installnow!:string;
  updateavailable!:string;
  connected!:string;
  disconnected!:string
  online!:string;
  offline!:string;
  bgclass!:string;
  isIframe!: boolean;
  isiproduct: boolean=false;
  subcriptionsw!: Subscription;
    //keep refs to subscriptions to be able to unsubscribe later
    private popupOpenSubscription!: Subscription;
    private popupCloseSubscription!: Subscription;
    private initializeSubscription!: Subscription;
    private statusChangeSubscription!: Subscription;
    private revokeChoiceSubscription!: Subscription;
    private noCookieLawSubscription!: Subscription;
  deferredPrompt: any;
  DONTASK: any;
  NO: any;
  YES!: string;
  sideBarIsOpened: boolean=false;
  navitems: NavItem[]=[];
  lastbg: any;
  counter$: any;
  isinstall: boolean= false;
  currentversion!:string;
  newversion!:string;
  TOGGLE: any;
  // titleOnderwijsleidraad: string="";
  // titleoer: string="";
  isoer: boolean=false;
    constructor(
      private router: Router,
      private swUpdate: SwUpdate,
      private ccService: NgcCookieConsentService,
      private translateService:TranslateService,
      private titleService: Title,
      private metaService: Meta,
      private cd: ChangeDetectorRef,

      private messageService: MessageService,
      private winRef: WindowRef,
      private renderer: Renderer2,
      private cookies:CookieService,
      private _messageService: MessageService,
      private _insightsService: AppInsightsService,
      private appRef: ApplicationRef,
      private _loaderService: LoaderService,
      private _signalrService:SignalrService,
      // private _texttospeechService:TextSelectionService
       )
  {
    this.ccService =ccService;
    this.bgclass =`bg${Math.floor(Math.random() * 12) + 1}`;
    this.renderer.addClass(document.body, this.bgclass);
            //Bepalen tonen installatie scherm
    this.lastbg = this.winRef.nativeWindow.localStorage.getItem("installfirst");

        const isIOS = () => {
          const userAgent = window.navigator.userAgent.toLowerCase();
          return /iphone|ipad|ipod/.test( userAgent );
        }
         this.isIOS =isIOS();
        //  this.logServices.buildPublisher();

      this.subscription =  router.events.subscribe(
        ( routerEvent: Event)=>{
          this.checkRouterEvent(routerEvent);
        });
      
     // Don't try to squeeze our own titlebar if the window is too narrow.
      if (this.winRef.HasWindowControlsOverlay) {
        this.winRef.windowControlsOverlay.addEventListener('geometrychange', () => {
          const { width } =  this.winRef.windowControlsOverlay.getTitlebarAreaRect();
      
          // Yes, we could do this with a media-query, but we only care
          // if the window-controls-overlay feature is being used.
          document.body.classList.toggle('narrow', width < 250);
        });
      }
      if ('windowControlsOverlay' in navigator) {
       fromEvent(document, 'geometrychange').pipe(
       debounceTime(200)).
       subscribe(ev => { 
        const isOverlayVisible =  this.winRef.windowControlsOverlay.visible;      
        // Get the size and position of the title bar area.
        const titleBarRect = this.winRef.windowControlsOverlay.titlebarAreaRect;
    
        console.log(`The overlay is ${isOverlayVisible ? 'visible' : 'hidden'}, the title bar width is ${titleBarRect.width}px`);
       });
        // this.winRef.windowControlsOverlay().addEventListener('geometrychange', debounce((e:any) => {
        //   // Detect if the Window Controls Overlay is visible.

        // }, 200));
      }
  }

  ngOnInit(): void {

        this.isIframe = window !== window.parent && !window.opener;
        if( this.swUpdate.isEnabled){
       this.swUpdate.versionUpdates.pipe(
      //  tap((event)=>{console.log("Update",event)}),
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => (
          {
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))).subscribe((evt) => {
          try{
            const regexp = new RegExp('^(\\d{4})(\\d{2})(\\d{2}).(\\d+)$');
            let versioncurrent ="";
            try {
              if(evt.current.appData!==undefined)
              {
                let { changelog ,changeDate}:any = evt.current.appData;
                versioncurrent =`${changelog}`.replace('versie',this.currentversion);
              }
            }
            catch(e)
            { console.log(e);
            };

            if(evt.available.appData!==undefined)
            {
            let { changelog ,changeDate, changeType}:any = evt.available.appData;

            const result = changeDate.match(regexp);
            let version ="";
            if(result && result.length>3)
            {
              version =`${result[3]}-${result[2]}-${result[1]} | build ${result[4]}\n\n`;
              this.winRef.nativeWindow.localStorage.setItem("version",`${changelog}${version}`);
              this.winRef.nativeWindow.localStorage.setItem("hash",`${evt.available.hash}`);
            }
            this._messageService.clear();
            this._messageService.add({key: 'update', sticky: true, severity:'warn', summary:`${this.updateavailable}`, detail:`${changeType}${versioncurrent}${changelog.replace('versie',this.newversion)}${version} ${this.installnow}`});
            }
          }
          catch(e)
          { console.log(e);

          };
      });
 }

       const appIsStable$ = this.appRef.isStable.pipe(first(stable => stable)
       ,tap(stable =>{

         })
       );
        appIsStable$.subscribe(()=>{
          if( this._signalrService.state!=signalR.HubConnectionState.Connected && this._signalrService.state!=signalR.HubConnectionState.Connecting)
          this._signalrService.initiateSignalrConnection();
        //  this.cleanUpCache();
        });
         const every30minutes$ = interval( 180 * 1000);
         const everySixHoursOnceAppIsStable$ = concat(appIsStable$, every30minutes$);
         everySixHoursOnceAppIsStable$.subscribe(() => {
           this.checkForUpdate();
         });
         this._signalrService.hub.subscribe((data: ISqlChanged) => {
          if(data!==null)
          {
            if( data.counter)
           {
            const counter:ICounter=(data.counter as ICounter);
            this.counter$= of(counter.online)
           }
          }
        });
        registerLocaleData(localeNl, 'nl');
        this.translateService.addLangs(['en', 'nl']);
        this.translateService.setDefaultLang('nl');

        const browserLang = this.translateService.getBrowserLang();
        this.translateService.use(browserLang?.match(/en|nl/) ? browserLang : 'nl');
        this.sideBarIsOpened = true;

        //this.GetLanguageText();

        if (window.addEventListener ) {
          /*
              Works well in Firefox and Opera with the
              Work Offline option in the File menu.
              Pulling the ethernet cable doesn't seem to trigger it.
              Later Google Chrome and Safari seem to trigger it well
          */
          window.addEventListener("online", () =>{this.isOnline()}, false);
          window.addEventListener("offline", () =>{this.isOffline()} , false);
        }

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
      () => {
        this.ccService.getConfig()
        // you can use this.ccService.getConfig() to do stuff...
        // console.log('popupOpen');
      });
      this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
        () => {
                      // you can use this.ccService.getConfig() to do stuff...
          console.log('popuClose');
        });

      this.initializeSubscription = this.ccService.initialize$.subscribe(
        (event: NgcInitializeEvent) => {
          var type = this.ccService.getConfig().type;
          var didConsent = event.status== "allow";
          if (type == 'opt-in' && didConsent) {
            // enable cookies
          }
          if (type == 'opt-out' && !didConsent) {
            // disable cookies
          }
          // console.log(`initialize: ${JSON.stringify(event)}`);
        });

      this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
        (event: NgcStatusChangeEvent) => {
          var type = this.ccService.getConfig().type;
          var didConsent =event.status== "allow";
          if (type == 'opt-in' && didConsent) {
            // enable cookies
          }
          if (type == 'opt-out' && !didConsent) {
            // disable cookies
          }
          console.log(`statusChange: ${JSON.stringify(event)}`);
        });

      this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
        () => {
          // you can use this.ccService.getConfig() to do stuff...
          console.log(`revokeChoice`);
        });

      this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
        (event: NgcNoCookieLawEvent) => {
          var type = this.ccService.getConfig().type;
          if (type == 'opt-in') {
            // disable cookies
          }
          if (type == 'opt-out') {
            // enable cookies
          }
          console.log(`noCookieLaw: ${JSON.stringify(event)}`);
        });

        this.translateService.onLangChange.subscribe(() => {
          this.GetLanguageText();
        });

}
  public getRouterOutletState(outlet:any) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }
  /**
   * Checks for updates from the service worker.
   */
  private checkForUpdate() {
    if(this.swUpdate.isEnabled)
     this.swUpdate.checkForUpdate();
  }

  /**
   * Checks if the application is currently online.
   * Clears any existing messages and adds a success message indicating that the application is online.
   */
  private isOnline() {
    this.messageService.clear();
    this.messageService.add({key:"onoff" ,severity:'success', summary: 'Online', detail:'Verbonden'});
  }

  /**
   * Checks if the application is currently offline.
   * Clears any existing messages and adds a new message to the message service.
   * The new message is sticky and contains the summary and detail from the current message.
   */
  private isOffline() {
    this.messageService.clear();
    this._messageService.add({key: 'update', sticky: true, severity:'', summary:this.message.summary, detail:this.message.detail});
    // this.messageService.add({key:"onoff" ,severity:'warn', summary: 'Offline', detail:'Geen verbinding'});
  }

  private cleanUpCache() {

    try{
      caches.keys().then((cacheNames:string[]) => {
        return Promise.all(
          cacheNames.filter(cacheName =>
               cacheName.indexOf(`ngsw:/:${this.winRef.nativeWindow.localStorage.getItem("hash")}`)==-1 &&
               cacheName.indexOf(`ngsw:/:db:${this.winRef.nativeWindow.localStorage.getItem("hash")}`)==-1 &&
               cacheName.indexOf('control')==-1 &&
              cacheName.indexOf('api')==-1
            // Return true if you want to remove this cache,
            // but remember that caches are shared across
            // the whole origin
          ).map(cacheName =>{
            return caches.delete(cacheName);
          })
        );
      });
    }
    catch(e)
    {console.log(e);};
  }

  @HostListener('window:beforeunload', ['$event'])
        /**
         * Handles the unload event of the application.
         * Stops the SignalR connection.
         */
        unloadHandler() {
        this._signalrService.stopSignalrConnection();
  }

  /**
   * Shows the installation prompt for iOS devices.
   * Checks if the user is using an iOS device and if the conditions for showing the installation prompt are met.
   * If the conditions are met, it either sets a timer to show the prompt after a delay or directly shows the prompt.
   */
   private showInstallForiOS() {

    const userAgent = window.navigator.userAgent.toLowerCase();
    let { isIos, os16_4_1_or_higher, isSafari ,deviceType}:IiOsInfo = iOS.iOsInfo(userAgent);
    if (isIos) {
      if (!this.winRef.standalone && isSafari && deviceType!=="ipad") {
        timer(10_000, 3600_000).subscribe(n=> this.isinstall = true);
      }
      else if (!this.winRef.standalone && isSafari) {

        this.showInstalPWA(deviceType );
      }
    }
  }
  /**
   * Shows the installation of PWA based on the device type.
   * @param {string} deviceType - The type of device (e.g., "ipad").
   */
  showInstalPWA(deviceType:string) {
    this._messageService.clear();
    this.isiproduct=true;
    if(deviceType==="ipad"){
    this.message.data=this.messageTap2Ipad;
    this._messageService.add(this.message);
    }
  }

  /**
   * Installs the browser app if the conditions are met.
   */
  private InstallBrowserApp() {

    if ((navigator as any).standalone == undefined) {
      // It's not iOS
      if (window.matchMedia('(display-mode: browser').matches) {
          // We are in the browser
          window.addEventListener('beforeinstallprompt', event => {
            // prevent the mini-infobar by calling
              event.preventDefault();
              this.deferredPrompt = event;
              this._messageService.clear();
              this._messageService.add({key: 'install', sticky: true, severity:'', summary:this.message.summary, detail:this.message.detail});
              return false;
          });
      }
    }
  }

  /**
   * Handles the confirmation action.
   */
  onConfirm()
  {
    if (this.deferredPrompt) {
      this.deferredPrompt.prompt();

      this.deferredPrompt.userChoice.then((result:any )=> {
              if (result.outcome == 'dismissed') {

                this._insightsService.logEvent("PWA",{"install":"It was NOT installed"});
              } else {
                this._insightsService.logEvent("PWA",{"install":"It was installed"});
              }
              this.winRef.nativeWindow.localStorage.setItem("installfirst","3");
              this.deferredPrompt = null;
          });
      }
  }
  onReject() {
    this.winRef.nativeWindow.localStorage.setItem("installfirst","3");
    this.cookies.set("iOsShowInstall","0",90);
    this._messageService.clear('install');
  }
  onRejectUpdate(){
    this._messageService.clear('update');
  }

  /**
   * Handles the confirmation of an update.
   * Clears the 'update' message, activates the update, and reloads the page.
   */
  onConfirmUpdate()
  {
    this._messageService.clear('update');
   // this.cleanUpCache();
    this.swUpdate.activateUpdate().then(() => {
      location.reload();
    })
  }


  checkRouterEvent(routerEvent:Event): void{
  if(routerEvent instanceof NavigationStart){
      this.loading= true;
  }
  if(routerEvent instanceof NavigationEnd||
      routerEvent instanceof NavigationError ||
      routerEvent instanceof NavigationCancel
      ){
        this.loading= false;
        if(this.lastbg!==null && !this.isoer)
          {
            if(+this.lastbg===1)
            {
              this.winRef.nativeWindow.localStorage.setItem("installfirst","2");
     
            }
            if(+this.lastbg===2)
            {
              this.winRef.nativeWindow.localStorage.setItem("installfirst","3");
     
            }
            if(+this.lastbg < 3)
            {
              if( this.swUpdate.isEnabled &&  this.winRef.nativeWindow.location.href!==`https://${environment.domain}/home`)
              {
                this.InstallBrowserApp();
                this.showInstallForiOS();
              }
            }
          }
          else{
             this.winRef.nativeWindow.localStorage.setItem("installfirst","1");
          }
    }
    if(routerEvent instanceof ResolveEnd){
      this.isoer =routerEvent.urlAfterRedirects === '/home' ?true:false;

    }
  }


  doNothing()
  {
    return;
  }

  private setvalues(app: any,connect: any,install: any,cookie:any) {

    // this.titleoer = app['TITLEOER'];
    // this.titleOnderwijsleidraad = app['TITLE'];
    this.title = app['TITLE'];


    this.YES =app['YES'];
    this.NO =app['NO'];
    this.TOGGLE =app['TOGGLE'];
    this.titleService.setTitle(this.title);
    this.connected=connect['connected'];
    this.disconnected=connect['disconnected'];
    this.online=connect['online'];
    this.offline=connect['offline'];
    this.installnow =install['installnow'];
    this.updateavailable =install['updateavailable'];
    this.currentversion =install['CURRENTVERSION'];
    this.newversion =install['NEWVERSION'];

    this.DONTASK =install['DONTASK'];

    this.messageTap2Ipad =install['tap3'];

    this.message ={key: 'install', sticky: true, severity:install['tap1'],
    summary:install['summary'],
    detail:install['detail'],
    data:install['tap2']
   };
   if(!this.isIOS){
    this.titleService.setTitle(this.title);
    }
    let logourl = `https://${environment.domain}/assets/img/logo.jpg`;
    const dn = new Date();
    // adjust 0 before single digit date
    let date = ("0" + dn.getDate()).slice(-2);
    let month = ("0" + (dn.getMonth() + 1)).slice(-2);
    let year = dn.getFullYear();

       this.metaService.addTags(
         [
           {name: 'viewport', content: 'width=device-width, initial-scale=1'},
         //  {name: 'description', content:this.title},
           {name: 'theme-color', content:'#B41313'},
           {name: 'robots', content: 'INDEX, FOLLOW'},
           {name: 'author', content: 'ROC - Nova College'},
           {name: 'publisher', content: 'ROC - Nova College, Diakenhuisweg 45, 2033AP Haarlem, tel.: +31 32 53 20 00'},
           {name: 'keywords', content: 'ROC,Nova College,OLD,Onderwijsleidraad,Nova,'+ this.title},
           {name: 'apple-mobile-web-app-capable', content:'yes'},
           {name: 'apple-mobile-web-app-status-bar-style', content:'translucent'},
           {name: 'msapplication-TileColor', content:'#ffffff'},
           {name: 'msapplication-TileImage', content:'/ms-icon-144x144.png'},
           {name: 'msapplication-config', content:'/browserconfig.xml'},
           {httpEquiv: 'Content-Type', content: 'text/html'},
           {property: 'og:title',content:this.title},
           {property: 'og:description', content:this.title},
           {property: 'og:url', content:`https://${environment.domain}`},
           {property: 'og:image', content: logourl},
           {property: 'og:image:secure_url', content: logourl},
           {property: 'og:image:url', content:logourl},
           {property: 'og:image:type', content:'image/jpeg'},
           {property: 'og:type', content: "website"},
           {content:app['TITLE']},
           {name: 'date', content: `${year}-${month}-${date}`, scheme: 'YYYY-MM-DD'},
           {charset: 'UTF-8'}
         ]);

     this.ccService.getConfig().content = this.ccService.getConfig().content || {};
     // Override default messages with the translated ones
       const ccconfig = this.ccService.getConfig();
       if(ccconfig&& ccconfig.content)
        { ccconfig.content.header = cookie['header'];
         ccconfig.content.message = cookie['message'];
         ccconfig.content.dismiss = cookie['dismiss'];
         ccconfig.content.allow = cookie['allow'];
         ccconfig.content.deny = cookie['deny'];
         ccconfig.content.link = cookie['link'];
         ccconfig.content.policy = cookie['policy'];
       }
     this.ccService.destroy(); //remove previous cookie bar (with default messages)
     this.ccService.init(this.ccService.getConfig());
  }

  private GetLanguageText() {

    const data = this.translateService.instant('APP');
    const connect = this.translateService.instant('CONNECT');
    const install = this.translateService.instant('INSTALL');
    const cookie = this.translateService.instant('cookie');
    this.setvalues(data,connect,install,cookie);
  }
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }
  ngOnDestroy() {
  // unsubscribe to cookieconsent observables to prevent memory leaks
  this.popupOpenSubscription.unsubscribe();
  this.popupCloseSubscription.unsubscribe();
  this.initializeSubscription.unsubscribe();
  this.statusChangeSubscription.unsubscribe();
  this.revokeChoiceSubscription.unsubscribe();
  this.noCookieLawSubscription.unsubscribe();
  this.renderer.removeClass(document.body, this.bgclass);
  }
}
