import { Injectable } from '@angular/core';
import { Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { Observable,Subject } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators'

import { ILoaderState } from './Iloader';

@Injectable()
export class LoaderService {

    private loaderSubject = new Subject<ILoaderState>();

    loaderState$ = this.loaderSubject.asObservable();
    rr:Observable<RouterEvent> =this.router.events as Observable<RouterEvent>;

    constructor(private router: Router) { }

    isNavigationPending$: Observable<boolean> = this.rr.pipe(
      filter((event: RouterEvent) => this.isConsideredEvent(event)),
      map((event: RouterEvent) => this.isNavigationStart(event)),
      distinctUntilChanged()
    );
  
    private isConsideredEvent(event: RouterEvent): boolean {
      return this.isNavigationStart(event)
        || this.isNavigationEnd(event);
    }
  
    private isNavigationStart(event: RouterEvent): boolean {
      return event instanceof NavigationStart;
    }
  
    private isNavigationEnd(event: RouterEvent): boolean {
      return event instanceof NavigationEnd
        || event instanceof NavigationCancel
        || event instanceof NavigationError;
    }

    show():void {
        this.loaderSubject.next(<ILoaderState>{show: true});
    }

    hide():void {
        this.loaderSubject.next(<ILoaderState>{show: false});
    }
}
