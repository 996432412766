import { Injectable} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IApiState } from './Iapistate';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '../../jwthelper.services';
import { MsalService } from '@azure/msal-angular';

@Injectable()
export class ApiTokenService {

  private apiSubject = new Subject<IApiState>();
  apiRole$:Observable<IApiState>;
  endpoint!:string;
  roles: string[]=[];
  token!: string;
  userData: any;
  userRoleData: any;

  constructor(
     private _authService: MsalService,
    private _router: Router,
    private _jwthelper:JwtHelperService,
  ) {
      this.apiRole$ = this.apiSubject.asObservable();
  }

  // Ophalen van api token  en opslaan na succes volle inlog en daarna de rol bepalen
  getApiToken(){
      this.getRoleFromApiToken();
      this._router.navigate(['dashboard']);
  }

  getRoleFromApiToken() {
    const scopes = [environment.resources.onderwijsleidraadApi.resourceScope];
    const account = this._authService.instance.getAllAccounts().filter( f=> { return f.tenantId==environment.auth.tenantId})[0];
    this._authService.acquireTokenSilent({scopes, account}).toPromise().then((response: any): void  => {
      this.token= response.accessToken;
            }).finally( ()=>{
              let apiState:IApiState={
                isAdmin:false,
                hasToken:false,
                isInvoerder: false,
                isTester: false,
                hasStudentIfo:false,
                isApprover:false,
                isOpleidingsmanager:false
              };
              if(this.token==undefined)
              {
                this.apiSubject.next(apiState);
              }
               else if(this._jwthelper.getRoles(this.token))
              {
                this.userRoleData = <string[]><unknown>this._jwthelper.getRoles(this.token);
                const expectedRole = [environment.roles.roleAdmin,environment.roles.roleInvoerder];
                if(this.testRole(expectedRole)) {
                  apiState.hasToken=true;
                }
                if(this.testRole([environment.roles.roleAdmin]))
                {
                  apiState.isAdmin=true;
                }
                if(this.testRole([environment.roles.roleInvoerder]))
                {
                  apiState.isInvoerder=true;
                }
                if( this.testRole([environment.roles.roleReadOnly]))
                {
                  apiState.hasStudentIfo=true;
                }
                if(this.testRole([environment.roles.roleGoedkeurder]))
                {
                  apiState.isApprover=true;
                }
                if(this.testRole([environment.roles.roleOpleidingsmanager]))
                {
                  apiState.isOpleidingsmanager=true;
                }
              }
              this.apiSubject.next(apiState);

            }
          );
      }
      private testGroup(expectedGroup: any):boolean {
        if(expectedGroup==null)
        {
          return false;
        }
        let hasGroup = false;

        expectedGroup.forEach((element:any) => {
          if(!hasGroup)
          {hasGroup =this.userData.includes(element);}
        });
        return hasGroup;
      }

      private testRole(expectedRole: any):boolean {
        if(expectedRole==null)
        {
          return false;
        }
        let hasRole = false;

        expectedRole.forEach((element:any) => {
          if(!hasRole)
          {hasRole =this.userRoleData.includes(element);}
        });
        return hasRole;
      }
}
