import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { AppInsightsService } from './services/app-insights.service';
@Injectable()
export class MonitoringErrorHandler extends ErrorHandler {
    constructor(private injector: Injector) {
        super();
    }
    override handleError(error: any): void {
        const monitoringService = this.injector.get(AppInsightsService);
        monitoringService.logError(error);
        super.handleError(error);
    }
}
