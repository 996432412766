import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './pages/page-not-found.component';
import { ErrorDialogComponent } from './components/error/error-dialog.component';
import { HeartBeatComponent } from './components/heartbeat/heart-beat.component';
import { MenuComponent } from './components/menu/items.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard, MsalInterceptor, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { LoadingInterceptorService } from './core/loading.interceptor.service';
import { AppPreloadingStrategy } from './helper/app-preload-strategy';
import { ErrordialogService } from './core/services/errordialog.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiTokenService } from './core/services/apitoken/api-token.service';
import { JwtHelperService } from './core/jwthelper.services';
import { AppInsightsService } from './core/services/app-insights.service';
import { APP_BASE_HREF } from '@angular/common';
import { WindowRef } from './window-ref';
import { MessageService } from 'primeng/api';
import { ErrorInterceptor } from './core/error.interceptor';
import { SignalrService } from './core/services/signalR/SignalrService';
import { NgcCookieConsentModule,NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PrimeNGModule } from './shared/primeng.modules';
import { CookieService } from 'ngx-cookie-service';
import { MSALInstanceFactory } from './core/auth/msal-instance-factory';
import { MSALInterceptorConfigFactory } from './core/auth/msal-interceptor-config-factory';
import { MSALGuardConfigFactory } from './core/auth/msal-guard-config-factory';
import { TextSelectionService } from './helper/selected-text';
import { MonitoringErrorHandler } from './core/error.handler';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CoreModule } from './core/core.module';
import { MaterialModules } from './shared/material.modules';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { AuthService } from './core/services/auth.services';
import { StartComponent } from './components/dashboard/start.component';
import { FocusDirective } from './directive/focus.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownService } from './core/services/api/dropdown.services';
import { SearchComponent } from './components/search/search.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { PublicComponent } from './components/oer/public/public.component';

const elem:any=document.getElementById("cookie-consent-content")!==null?document.getElementById("cookie-consent-content"):undefined;
const cookieConfig:NgcCookieConsentConfig={
  cookie: {
    domain: environment.domain
  },
  position: "bottom",
  theme: "classic",
  palette: {
  popup: {
  background: "#000000",
  text: "#ffffff",
      link: "#ffffff"
    },
    button: {
     background: "#f1d600",
      text: "#000000",
      border: "transparent"
    }
  },
  type: 'info',
  content: {
    message: "This website uses cookies to ensure you get the best experience on our website.",
    dismiss: "Got it!",
    deny: "Refuse cookies",
    link: "Learn more",
    href: `https://${environment.domain}/info/policy`,
    policy: 'Cookie Policy'

  },
  container: elem
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    ErrorDialogComponent,
    ToolbarComponent,
    HeartBeatComponent,
    // DashboardComponent,
    MenuComponent,
    StartComponent,
    SearchComponent,
    FocusDirective,
    PublicComponent
  ],
  imports: [
    CoreModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FlexLayoutModule,
    PrimeNGModule,
    MaterialModules,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register(environment.serviceWorkerScript, { enabled: environment.production, scope: "/" }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    CKEditorModule
  ],
  exports: [FontAwesomeModule],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },

    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },

    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptorService,
      multi: true
    },
    AppPreloadingStrategy,
    ErrordialogService,
    DialogService,
    TextSelectionService,
    ApiTokenService,
    JwtHelperService,
    AppInsightsService, {
      provide: ErrorHandler,
      useClass: MonitoringErrorHandler
    },
    WindowRef,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    MessageService,
    CookieService,
    DropdownService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    SignalrService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
