import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FragmentService } from 'src/app/core/services/api/fragment.service';
import { IFragment } from 'src/app/core/model/fragment.interface';
import { OERService } from 'src/app/core/services/api/oer.service';
import { QueryOptions } from 'src/app/core/services/query.options';

@Component({
  selector: 'old-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.css']
})
export class TileComponent implements OnInit {
//id:number;
fragmentName:string="";
content:string="";
htmlValue:SafeHtml="";
regex = /href=\"https:\/\/|href=\"http:\/\//gi
//orders$: Observable<IFragment[]>;
  constructor(public ref: DynamicDialogRef,
    public sanitizer: DomSanitizer,
    public config: DynamicDialogConfig, private _fragmentServices:FragmentService,
    private _oerService:OERService,) {
    }
  ngOnInit() {
    this.getTitleData();
    this.fragmentName= this.config.data.fragmentName;

  }
  private getTitleData() {

    if( !this.config.data.oer)
    this._fragmentServices.read(this.config.data.id).subscribe((result: IFragment[]) => {
      if (result != null) {
        result.forEach(element => {


          element.content =element.content.replace(this.regex,'target="_blank" href="https://').replaceAll('\\"', '"')
          if (!element.content.startsWith("[{")) {
            this.content = this.content + element.content;
          }

        });
        this.htmlValue = this.sanitizer.bypassSecurityTrustHtml(this.content);
      }
    });
    else
    {
      let o: QueryOptions = new QueryOptions;
     o.fields = "content";
     o.path ="fragment" ;
      o.id =this.config.data.id;
      this._oerService.listByPath(o).subscribe((result: IFragment[]) => {
      if (result != null) {
        result.forEach(element => {
          element.content =element.content.replace(this.regex,'target="_blank" href="https://').replaceAll('\\"', '"')
          if (!element.content.startsWith("[{")) {
            this.content = this.content + element.content;
          }

        });
       this.htmlValue= this.sanitizer.bypassSecurityTrustHtml(this.content);
      }
    });
  }
  }
}
