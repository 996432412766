import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/authorization/login/login.component';
import { FailedComponent } from './components/failed/failed.component';
import { NoWebpushComponent } from './components/info/no-webpush/no-webpush.component';
import { LogoutComponent } from './components/authorization/logout/logout.component';
import { PageNotFoundComponent } from './pages/page-not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { StartComponent } from './components/dashboard/start.component';
import { PublicComponent } from './components/oer/public/public.component';

const routes: Routes = [

  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'nl', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'en', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'home', component: PublicComponent},
  { path: 'dashboard', component: StartComponent, canActivate: [MsalGuard]},
  {
    path: 'controls',
    data: { preload: false, delay: true },
    loadChildren: () => import('./controls/controls.module').then(m => m.ControlsModule)
  },
  {
    path: 'info',
    data: { preload: false, delay: true },
    loadChildren: () => import('./components/info/info.modules').then(m => m.InfoModule)
  },
  {
    path: 'management',
    data: { preload: true },
    loadChildren: () => import('./components/management/management.module').then(m => m.ManagementModule),
    canActivate: [
      MsalGuard
    ]
  },
  { path: 'login-failed', component: FailedComponent},
   { path:'nowebpush', component:NoWebpushComponent},
   { path: 'logout', component: LogoutComponent },
   {path: 'login', component: LoginComponent},
   { path: '404', component: PageNotFoundComponent},
   { path: '**', pathMatch: 'full', component: PageNotFoundComponent}
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: !environment.production, useHash: false, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
