/**
 * Utility class for converting dates.
 */
export class DateConvert {
    /**
     * Deserializes the given data by parsing it and reviving any Date objects.
     * @param data The data to deserialize.
     * @returns The deserialized data.
     */
    public static Deserialize(data: any): any {
        return JSON.parse(data, DateConvert.ReviveDateTime);
    }

    /**
     * Revives a Date object from a string representation.
     * @param key The key of the object being revived.
     * @param value The value of the object being revived.
     * @returns The revived Date object, or the original value if it is not a string or does not match the expected format.
     */
    private static ReviveDateTime(key: any, value: any): Date {
        if (typeof value === 'string') {
            let a = /\/Date\((\d*\+0[1|2]00)\)\//.exec(value);
            if (a) {
                let t = a[1].replace('+0100', '').replace('+0200', '');
                return new Date(+t); // The plus sign converts it to a number
            }
        }
        return value;
    }
}