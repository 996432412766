import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "src/environments/environment";
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
      auth: {
        clientId: environment.auth.clientId,
        redirectUri: environment.auth.redirectUri,
        authority:environment.auth.authority,
        postLogoutRedirectUri:`https://${environment.domain}/logout`
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage },
      system: {
        loggerOptions: {
          loggerCallback,
          logLevel: LogLevel.Error,
          piiLoggingEnabled: false
        }
      }
    });
  }

  export function loggerCallback(logLevel: LogLevel, message: string) {
    switch (logLevel) {
        case LogLevel.Error:
            console.error(message);
            return;
        case LogLevel.Info:
            console.info(message);
            return;
        case LogLevel.Verbose:
            console.debug(message);
            return;
        case LogLevel.Warning:
            console.warn(message);
            return;
  }
}