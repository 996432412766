import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource.service';
import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { ConfigService } from '../../config.service';
import { ILeerweg } from '../../model/leerweg.interface';
@Injectable()
export class LeerwegService extends ResourceService<ILeerweg> {
  constructor(
    httpClient: HttpClient,
    _alertServices:AlertService,
    private configService: ConfigService) {
    super(
      httpClient,
      configService.apiAzure,
      _alertServices,
      'leerweg'
      );
  }
}
