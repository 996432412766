import { Injectable } from '@angular/core';

import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { environment } from './../../../environments/environment';
import { Observable, Subscription } from 'rxjs';
import { ResolveEnd, ActivatedRouteSnapshot, Router, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {
  private routerSubscription: Subscription;
  private appInsights = new ApplicationInsights({ config: {
    instrumentationKey: environment.appInsights.instrumentationKey
    /* ...Other Configuration Options... */
  } });
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    if(environment.appInsights.instrumentationKey) {
        this.appInsights.loadAppInsights();
    }
   let  rr:Observable<ResolveEnd> =this.router.events as Observable<ResolveEnd>;
    this.routerSubscription = rr.pipe(
    filter(event => event instanceof ResolveEnd)
    ).subscribe((event: ResolveEnd) => {
        const activatedComponent = this.getActivatedComponent(event.state.root);
        if (activatedComponent) {
            this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
        }
    });
   }
   private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
    if (snapshot.firstChild) {
        return this.getActivatedComponent(snapshot.firstChild);
    }
    return snapshot.component;
  }

  private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
    let path = '';
    if (snapshot.routeConfig) {
        path += snapshot.routeConfig.path;
    }

    if (snapshot.firstChild) {
        return path + this.getRouteTemplate(snapshot.firstChild);
    }
    return path;
}
setAuthenticatedUserId(userId: string): void {
    this.appInsights.setAuthenticatedUserContext(userId);
}

private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
  if (!properties) {
      properties = {};
  }
  //add your custom properties such as app version

  return properties;
}
  public logPageView(name?: string, uri?: string) {
    this.appInsights.trackPageView({name, uri});
  }
  public logEvent(name: string, properties?: { [key: string]: string } ){
    this.appInsights.trackEvent({ name: name}, this.AddGlobalProperties(properties));
  }
  public logError(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
}
}
