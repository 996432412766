import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import { startWith,map, debounceTime, tap } from 'rxjs/operators';
import { QueryOptions } from 'src/app/core/services/query.options';
import { DialogService } from 'primeng/dynamicdialog';
import { Sort } from 'src/app/helper/sort-tiles';
import { IOnderwijsleidraad } from 'src/app/core/model/onderwijsleidraad.interface';
import { ITileName } from 'src/app/core/model/tilename.interface';
import { TileNameService } from 'src/app/core/services/api/tilename.service';
import { OnderwijsleidraadService } from 'src/app/core/services/api/onderwijsleidraad.service';
import { TileComponent } from '../general/tile/tile.component';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { Filter } from 'src/app/helper/filter';
import { environment } from 'src/environments/environment';
import { TileTableComponent } from '../general/keuzedelentabel/tile-table.component';

@Component({
  selector: 'old-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  host: {'(input-blur)':'onInputBlur($event)'},
})
export class SearchComponent implements OnInit {
  public environment = environment;
  accountsData:any= [];
  o:QueryOptions= new QueryOptions;
  Changed: any;
  checked: boolean = false;
  show:boolean=true;
  Name!: string;
  onderwijsleidraden: IOnderwijsleidraad[]=[];
  searchtext!: string;
  arrayTileNames: ITileName[]=[];
  HEADER: any;
  RESULT: any;
  PHSEARCH: any;
  NOTHINGFOUND: any;
  form: FormGroup;
  results: any[]=[];
  VERSION: any;

  constructor(
    private _tileNameService:TileNameService,
    private _onderwijsleidraadService:OnderwijsleidraadService,
    private  _translate: TranslateService,
    public dialogService: DialogService,
  ) {
    this.form = new FormGroup({
      myControl: new FormControl()
    });


    this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.GetLanguageData()
    });
    this.GetLanguageData();
    this.o.toQueryMap();
  }

  filteredOptions!: Observable<string[]>;
  filteredOptions1!: Observable<string[]>;
  ngOnInit() {
    this.tileNamesOphalen();
    this.form.get('myControl')?.valueChanges.pipe(
      tap((event)=>{console.log("Update",event)}),
      debounceTime(550),
      startWith(''),
      map(val => this.filter(val as string)));

  }
  /**
   * Filters the data based on the provided value.
   * @param val - The value to filter the data with.
   * @returns An array of filtered data.
   */
  filter(val: string) {
    this.show = false;
    if(val=== undefined||val=="")
      return [];
    this.getData(val);
    return this.onderwijsleidraden;
    // return this.accountsData.filter(option =>
    //  option.displayname.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }
  // Cursor weg uit zoek box
  onInputBlur(val:any)
  {
    if(val.srcElement.value!=="")
    this.filteredOptions1 =this.filteredOptions;
  }

  /**
   * Retrieves tile names from the server and assigns them to the `arrayTileNames` property.
   */
  private tileNamesOphalen():void
  {
     let qo:QueryOptions= new QueryOptions;
     qo.fields ='name,code,color'
    this._tileNameService.list(qo).subscribe( (result:ITileName[]) =>{
      if(result!=null)
      {
        this.arrayTileNames= result;
      }
    });
  }
  /**
   * Retrieves the tile name based on the fragment type and type.
   * @param fragementType - The fragment type.
   * @param type - The type.
   * @returns The tile name.
   */
  getTileName(fragementType: any, type: any) {
    if (this.arrayTileNames == null || this.arrayTileNames == undefined)
      return '';
    let ft = this.arrayTileNames.filter((l) => {
      return l.code == fragementType.toString();
    })[0]['name'];
    if (type == 1) ft = ft.replace('-', '\n').replace('-', '\n');
    return ft;
  }

  /**
   * Clears the value of the myControl form control.
   */
  ACclear()
  {
    this.form.patchValue({
      myControl: null
      });
  }
  // Zoeken voor bewerken van item
  /**
   * Performs a search based on the provided query.
   * @param event - The event object containing the query.
   * @returns An array of search results.
   */
  search(event:any):any {
    let query = event.query;
    if(query=== undefined||query=="")
        return [];
      this.getData(query);
    this.results =this.onderwijsleidraden;
  }
  
  
  // Autocomplete gekozen item
  /**
   * Handles the ACSelect event.
   * 
   * @param event - The event object.
   */
  ACSelect(event:any) {
    let o = Filter.ToObjectByValue(this.arrayTileNames,event);
    // // Tonen bewerk in UI
    // this.errorStateEdit =true;
    //   this.GetItem(o[0].code);
  }

  /**
   * Displays a tile based on the provided data.
   * @param data - The data used to display the tile.
   */
  showTile(data:any):void {
    const t = data.FragmentType==80?TileTableComponent:TileComponent;
    const ref = this.dialogService.open(t, {
         data: {
            id: data.id,
            fragmentName:data.name
        },
        dismissableMask:true,
        header: data.name,
        width: '90%'
    });
  }
  //Ophalen van fragmenten welke voldoen aan de zoek tekst
  private getData(text:string):void
  {
    this.o.type = text
    this._onderwijsleidraadService.listByPath(this.o).subscribe( (result:IOnderwijsleidraad[]) =>{
      if(result!=null)
      {
        this.show = false;
        this.onderwijsleidraden=[]
        if(result!==null)
        {
          Sort.Tiles(result);
          result.forEach((r: IOnderwijsleidraad) => {
            if(r.tilesarray!==undefined)
            {
              r.tilesarray.forEach((tile: any) => {
                const tilename: string = this.getTileName(tile.fragmenttype, 1);
                const color: string = this.arrayTileNames.filter((l) => {
                  return l.code == tile.fragmenttype;
                })[0]['color'];
                tile['color'] = color;
                tile['name'] = tilename;
              });
            }
          });
          this.onderwijsleidraden=result;
          this.show = true;
        }
      }
      else{
        this.show = true;
        this.searchtext =text;
        this.onderwijsleidraden=[];
       }
    });
  }
  private setvalues( app:any,search: any) {

    this.HEADER = search['HEADER'];
    this.RESULT = search['RESULT'];
    this.PHSEARCH = search['PHSEARCH'];
    this.NOTHINGFOUND = search['NOTHINGFOUND'];
    this.VERSION = app['VERSION'];

  }
  private GetLanguageData() {
    const search = this._translate.instant('SEARCH');
    const app = this._translate.instant('APP');
    this.setvalues(app, search);
  }
}
