import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from './loader/loader.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Service that intercepts HTTP requests and handles loading screen display.
 */
export class LoadingInterceptorService {

  activeRequests: number = 0;

    constructor(
        private loadingScreenService: LoaderService
    ) { }

    /**
     * Intercepts HTTP requests and handles loading screen display.
     * @param request - The intercepted HTTP request.
     * @param next - The next HTTP handler in the chain.
     * @returns An observable of the HTTP event.
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.activeRequests === 0) {
            this.loadingScreenService.show();
        }

        this.activeRequests++;

        return next.handle(request).pipe(
            finalize(() => {
                this.activeRequests--;
                if (this.activeRequests === 0) {
                    this.loadingScreenService.hide();
                }
            })
        )
    };
}