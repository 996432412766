import { IOnderwijsleidraad } from "../core/model/onderwijsleidraad.interface";

export const Sorting =[ 10,20,30, 40,50,60,70,80,90,100,110,120,130,140,150,160,170,180,190,200,210,1000,1100 ];
/**
 * Utility class for sorting and filtering arrays of objects.
 */
export class Sort {

    /**
     * Sorts the given array of `IOnderwijsleidraad` objects and updates the `tilesarray` property of each object.
     * @param result - The array of `IOnderwijsleidraad` objects to be sorted.
     */
    public static Tiles(result: IOnderwijsleidraad[]) {
      result = result.sort((a, b) => (a.changed < b.changed) ? 1 : -1); // newest first
        for (let index = 0; index < result.length; index++) {
          const tiles: any[]=[];
          Sorting.forEach(key => {
            let found:boolean = false;
            if(result && result.length>0 && result[index].tiles !==undefined)
            {
              const ts:any=  result[index].tiles;
              if(ts!==undefined && ts!==null && ts.length>0)
              {
              const t:any = (JSON.parse(ts.toLowerCase()) as any[])
              t.filter((item:any) => {
                if (!found && item.fragmenttype == key) {
                  tiles.push(item);
                  found = true;
                  return false;
                }
                else
                  return true;
              });
              }
            }
          });
          result[index].tilesarray = tiles;

        }
      }

      /**
       * Filters and sorts an array of fragments based on a predefined sorting order.
       * @param result - The array of fragments to be filtered and sorted.
       * @returns The filtered and sorted array of fragments.
       */
      public static  Fragmenten(result: any[]):any[] {
        let results: any[]=[];
        Sorting.forEach(key=> {
                result = result.filter(item=>{
                if( item.fragmenttype == key) {
                   results.push(item);
                    //found = true;
                    return true;
                } else
                    return true;
            });
        });
        return results;
      }
}