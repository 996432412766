import { CreboService } from './crebo.services';
import { LeerwegService } from './leerweg.services';
import { LocatieService } from './locatie.service';
import { PeriodeService } from './cohort.services';
import { Observable, BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IPeriode } from '../../model/periode.interface';
import { ILocatie } from '../../model/locatie.interface';
import { ILeerweg } from '../../model/leerweg.interface';
import { ICrebo } from '../../model/crebo.interface';
import { QueryOptions } from '../query.options';
import { ILoaderState } from '../../loader/Iloader';
import { SortData } from 'src/app/helper/sort-data-by-name';
import { IDropdownOLD } from '../../model/dropdown.interface';
import { IApiState } from '../apitoken/Iapistate';
import { ApiTokenService } from '../apitoken/api-token.service';
import { OERService } from './oer.service';
@Injectable()
export class DropdownService{
   creboData: IDropdownOLD[]=[];
   leerwegData: IDropdownOLD[]=[];
   periodeData: IDropdownOLD[]=[];
   locatieData: IDropdownOLD[]=[];
   datalocatie: ILocatie[]=[];
   private datacreboSubject$ = new BehaviorSubject<IDropdownOLD[]>(this.creboData);
   datacreboObs$ :Observable<IDropdownOLD[]>;
   private dataleerwegSubject$ = new BehaviorSubject<IDropdownOLD[]>(this.leerwegData);
   dataleerwegObs$ :Observable<IDropdownOLD[]>;
   private dataperiodeSubject$ = new BehaviorSubject<IDropdownOLD[]>(this.periodeData);
   dataperiodeObs$ :Observable<IDropdownOLD[]>;
   private datalocatieSubject$ = new BehaviorSubject<IDropdownOLD[]>(this.locatieData);
   datalocatieObs$ :Observable<IDropdownOLD[]>;
   private locatiedataSubject$ = new BehaviorSubject<ILocatie[]>(this.datalocatie);
   locatiedataObs$ :Observable<ILocatie[]>;
   private loaderSubject = new Subject<ILoaderState>();
   loaderState$ = this.loaderSubject.asObservable();
  isstudent: boolean = false;
  constructor(
    private _creboService:CreboService,
    private _leerwegService:LeerwegService,
    private _locatieService:LocatieService,
    private _periodeService:PeriodeService,
    private _oerService:OERService,
    private _apiTokenService: ApiTokenService
  )
  {
    this.loaderSubject.next(<ILoaderState>{show: true});
    this.datacreboObs$ = this.datacreboSubject$.asObservable();
    this.dataleerwegObs$ = this.dataleerwegSubject$.asObservable();
    this.dataperiodeObs$ = this.dataperiodeSubject$.asObservable();
    this.datalocatieObs$ = this.datalocatieSubject$.asObservable();
    this.locatiedataObs$ = this.locatiedataSubject$.asObservable();
    this._apiTokenService.apiRole$.subscribe((hasStudent:IApiState) => {
      if(hasStudent.hasStudentIfo && !hasStudent.isInvoerder  && !hasStudent.isAdmin && !hasStudent.isOpleidingsmanager && !hasStudent.isApprover)
      {
          this.isstudent = true;
      }
      // this.getCreboData();
    });
    combineLatest(this.datacreboObs$,
      this.dataleerwegObs$,
      this.datalocatieObs$,
      this.dataperiodeObs$,
     (crebo,leerweg,locatie,periode) => ({crebo,leerweg,locatie,periode}))
    .subscribe(pair => {
      if(pair.crebo.length>0 &&
        pair.leerweg.length>0 &&
        pair.locatie.length>0 &&
        pair.periode.length>0
        )
      {
        this.loaderSubject.next(<ILoaderState>{show: false});
      }
   })
  }
  getCreboData()
  {
    this.creboData =[];
    let o: QueryOptions = new QueryOptions;
    o.fields = "code,name,leerweg";
    o.type = this.isstudent ? 1 : 0;
       this._creboService.list(o).subscribe((data: ICrebo[]) => {
         if (data != null) {
         let sData= SortData.By(data,"name");
         sData.forEach( (crebo:ICrebo)=>{
        const pushObject ={value:crebo.code,label:crebo.name+' ('+crebo.code+')'};
         if(this.creboData.find(x => x.value == crebo.code) === undefined)
             this.creboData.push(pushObject)
          });
          this.datacreboSubject$.next(this.creboData);
         }
       });
  }
  getLeerwegData(){
    this.leerwegData =[];
    let o: QueryOptions = new QueryOptions;
    o.fields = "id,code,desc";
    this._leerwegService.list(o).subscribe((data: ILeerweg[]) => {
      if (data != null) {
      data.forEach( (leerweg:ILeerweg)=>{ this.leerwegData.push(
        { value: leerweg.id, label: leerweg.code +' ('+ leerweg.desc+')'}
      )});
      this.dataleerwegSubject$.next(this.leerwegData);
      }
    });
  }
  getLocatieData(){
    this.locatieData =[];
    let o: QueryOptions = new QueryOptions;
    o.fields = "id,code,name,level,parentid";
    this._locatieService.list(o).subscribe((data: ILocatie[]) => {
      if (data != null) {
        this.datalocatie= data;
      let sData= SortData.By(data,"naam");
      sData.forEach( (locatie:ILocatie)=>{
          if(locatie.level==1)
          this.locatieData.push(
          { value: locatie.id, label: locatie.name}
        )});
        this.datalocatieSubject$.next(this.locatieData);
        this.locatiedataSubject$.next(this.datalocatie);
      }
    });
  }

  getLocatieDataPublic(){
    this.locatieData =[];
    let o: QueryOptions = new QueryOptions;
    o.fields = "id,code,name,level,parentid";
    o.path ="location" ;
    this._oerService.listByPath(o).subscribe((data: ILocatie[]) => {
      if (data != null) {
        this.datalocatie= data;
      let sData= SortData.By(data,"naam");
      sData.forEach( (locatie:ILocatie)=>{
          if(locatie.level==1)
          this.locatieData.push(
          { value: locatie.id, label: locatie.name}
        )});
        this.datalocatieSubject$.next(this.locatieData);
        this.locatiedataSubject$.next(this.datalocatie);
      }
    });
  }
  getLeerwegDataPublic(){
    this.leerwegData =[];
    let o: QueryOptions = new QueryOptions;
    o.fields = "id,code,desc";
    o.path ="leerweg" ;
    this._oerService.listByPath(o).subscribe((data: ILeerweg[]) => {
      if (data != null) {
      data.forEach( (leerweg:ILeerweg)=>{ this.leerwegData.push(
        { value: leerweg.id, label: leerweg.code +' ('+ leerweg.desc+')'}
      )});
      this.dataleerwegSubject$.next(this.leerwegData);
      }
    });
  }
  getPeriodeDataPublic(){
    this.periodeData =[];
    let o: QueryOptions = new QueryOptions;
    o.fields = "cohort,desc";
    o.path ="period" ;
    this._oerService.listByPath(o).subscribe((data: IPeriode[]) => {
      if (data != null) {
      data.reverse().forEach( (periode:IPeriode)=>{
        if(+periode.cohort>2324 &&+periode.cohort<3000)
        this.periodeData.push(
        { value: +periode.cohort, label: periode.desc}
      )});
      this.dataperiodeSubject$.next(SortData.By(this.periodeData,"label"));
      }
    });
  }
  getCreboDataPulic()
  {
    this.creboData =[];
    let o: QueryOptions = new QueryOptions;
    o.fields = "code,name,leerweg";
    o.path ="crebo" ;
       this._oerService.listByPath(o).subscribe((data: ICrebo[]) => {
         if (data != null) {
         let sData= SortData.By(data,"name");
         sData.forEach( (crebo:ICrebo)=>{
        const pushObject ={value:crebo.code,label:crebo.name+' ('+crebo.code+')'};
         if(this.creboData.find(x => x.value == crebo.code) === undefined)
             this.creboData.push(pushObject)
          });
          this.datacreboSubject$.next(this.creboData);
         }
       });
  }


  getPeriodeData(){
    this.periodeData =[];
    let skip:number = 0;
    let o: QueryOptions = new QueryOptions;
    o.fields = "cohort,desc,iscurrent";
    this._periodeService.list(o).subscribe((data: IPeriode[]) => {
      if (data != null) {
        data.sort((a, b) => (a.desc < b.desc) ? 1 : -1).reverse().forEach( (periode:IPeriode)=>{
          if(+periode.cohort>1900 &&+periode.cohort<3000 && skip<2)
          {
            if(periode.iscurrent || skip==1)
            {
              skip++;
            }
              this.periodeData.push({ value: +periode.cohort, label: periode.desc} );
          }
         } );
        this.dataperiodeSubject$.next(SortData.ByDesc(this.periodeData,"label"));
      }
    });
  }
}
