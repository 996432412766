import {GalleriaModule} from 'primeng/galleria';
import {MenubarModule} from 'primeng/menubar';
import {DropdownModule} from 'primeng/dropdown';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';

import {SpinnerModule} from 'primeng/spinner';

import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {ToastModule} from 'primeng/toast';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {DialogModule} from 'primeng/dialog';
import {FieldsetModule} from 'primeng/fieldset';

import {TableModule} from 'primeng/table';
import { CardModule } from 'primeng/card';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import { AutoCompleteModule } from 'primeng/autocomplete';

const modules = [
  ButtonModule,
  DialogModule,
  DynamicDialogModule,
  DropdownModule,
  FieldsetModule,
  FormsModule,
  GalleriaModule,
  InputTextModule,
  MenubarModule,
  MessagesModule,
  MessageModule,
  ProgressSpinnerModule,
  SpinnerModule,
  TableModule,
  ToastModule,
  CardModule,
  TieredMenuModule,
  OverlayPanelModule,
  AutoCompleteModule
];

@NgModule({
  imports: [modules],
  exports: [ modules]
})
export class PrimeNGModule { }
