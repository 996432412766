import { IStudent } from './student.interface';

export class StudentData implements IStudent{
  constructor(
   public id_leerweg: number,
   public id_location: number,
   public crebo: number,
   public id: number,
   public period: string
) {

 }

 static fromJSON(json: any): StudentData {
  let user = Object.create(StudentData.prototype);
  return Object.assign(user,json

  );
}
}
