<div class="block-header__image position-relative" style="background-image: url('/assets/img/oerbg.jpg')"></div>
<div class="with40px">
  <div class="intro__text">
   {{INTRO}}
  </div>
</div>
<div class="container">
  <form [formGroup]="form" (submit)="onSubmit()" novalidate class="education-overview__filters">
    <div fxLayout="column" fxLayoutGap="20px">
        <div flex=25>
             <p-dropdown formControlName="creboName" [showClear]="true" placeholder="{{CHOOSECREBO}}" (onClear)="onClear()"
             optionLabel="label" optionValue="value" [options]="creboOptions" [filter]="true" filterBy="label,value" dataKey="value"
             [ngClass]="(form.invalid && (form.dirty || form.touched) && formX['creboName'].errors?.['required']) ? 'has-error': ''">
             </p-dropdown>
        </div>
        <div flex=25>
             <p-dropdown formControlName="leerwegName" [showClear]="true" placeholder="{{SELPATH}}" (onClear)="onClear()"
             optionLabel="label" optionValue="value" [options]="leerwegOptions" dataKey="value"
             [ngClass]="(form.invalid && (form.dirty || form.touched) && formX['leerwegName'].errors?.['required']) ? 'has-error': ''"></p-dropdown>
        </div>
        <div flex=25>
          <p-dropdown formControlName="locatieName" [showClear]="true" placeholder="{{SELLOCATION}}" (onClear)="onClear()"
          optionLabel="label" optionValue="value" [options]="locationOptions"
          ></p-dropdown>
        </div>
        <div  flex=25>
          <p-button type="submit" class="searchbtn" [disabled]="form.pristine || form.invalid" icon="pi pi-search" iconPos="right" >
            {{SEARCH}}
          </p-button>
        </div>
      </div>
  </form>
  <div *ngIf="onderwijsleidraden.length==0 && show" class="notfound"><h3>{{NOTHINGFOUND}}</h3></div>

</div>
<div class="tiles" *ngIf="onderwijsleidraden.length > 0">
  <div *ngIf="onderwijsleidraden.length==1 && locatieId==0 && givenlocationMessage!==''"  ><h3>Deze opleiding wordt gegeven op de volgende locaties:  {{givenlocationMessage}}</h3></div>
      <div *ngFor="let o of onderwijsleidraden" class="margin15">
        <p-fieldset legend="{{o.name}}, {{VERSION}}{{o.changed*1000| date:' EEEE, d MMMM yyyy HH:mm'}}" [toggleable]="onderwijsleidraden.length > 1" [collapsed]="onderwijsleidraden.length > 1">
            <div *ngFor="let g of o.tilesarray">
              <div  [ngClass]="{ 'tegelnew':checked,'tegel':!checked }" (click)="showTile(g)" [style.background-color]="!checked?g.color:''" [style.border-color]="checked?g.color:''" [style.border-color]="checked? g.color:''" [style.color]="checked? g.color:'#FFF'">
                <span>{{g.name}}</span>
              </div>
          </div>
          </p-fieldset>
      </div>
</div>
<div #scrollMe>.</div>