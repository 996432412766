import {  AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, Subject, delay } from 'rxjs';
import { DestroySubscribers } from 'src/app/core/destroysubscribers';
import { ILoaderState } from 'src/app/core/loader/Iloader';
import { LoaderService } from 'src/app/core/loader/loader.service';
import { IDropdown, IDropdownOLD } from 'src/app/core/model/dropdown.interface';
import { ILocatie } from 'src/app/core/model/locatie.interface';
import { IOnderwijsleidraad } from 'src/app/core/model/onderwijsleidraad.interface';
import { ITileName } from 'src/app/core/model/tilename.interface';
import { DropdownService } from 'src/app/core/services/api/dropdown.services';
import { TileNameService } from 'src/app/core/services/api/tilename.service';
import { MetaService } from 'src/app/core/services/meta.service';
import { Sort } from 'src/app/helper/sort-tiles';
import { TileTableComponent } from '../../general/keuzedelentabel/tile-table.component';
import { TileComponent } from '../../general/tile/tile.component';
import { QueryOptions } from 'src/app/core/services/query.options';
import { OERService } from 'src/app/core/services/api/oer.service';
import { WindowRef } from 'src/app/window-ref';


@Component({
  selector: 'old-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
@DestroySubscribers()
export class PublicComponent implements AfterViewChecked, AfterViewInit{
  @ViewChild("scrollMe") myScrollContainer!: ElementRef;
  private dataoldSubject$ = new Subject<boolean>();
  dataoldObs$ :Observable<boolean>;
  arrayTileNames: ITileName[]=[];
  checked: boolean = false;
  title = 'OER';
  creboId:number=23064;
  cohortId:number=1920;
  leerwegId:number=1;
  locatieId:number=4;
  paramsCrebo: any;
  isLoading: boolean= true;
  form: FormGroup;
  creboOptions:IDropdownOLD[]=[];
  leerwegOptions:IDropdownOLD[]=[];
  cohortOptions: IDropdownOLD[]=[];
  locationOptions: IDropdownOLD[]=[];
  onderwijsleidraden: IOnderwijsleidraad[]=[];
  locatiedata: ILocatie[]=[];
  selectedCrebo: number=0;
  selectedLeerweg: number=0;
  selectedCohort: number=0;
  selectedLocatie: number=0;
  selectedId: number=0;
  HEADER: any;
  TAPTITLE: any;
  RESULT: any;
  NOTHINGFOUND: any;
  TAPSEARCH: any;
  SEARCH: any;
  SELLOCATION: any;
  LOCATION: any;
  SELPATH: any;
  PATH: any;
  CHOOSECREBO: any;
  CHOOSECOHORT: any;
  FOUND: any;
  VERSION: any;
  show:boolean=false;
  hasScrolled: boolean=true;
  INTRO: any;
  startscroll: boolean= false;
  loadfromparams: boolean=false;
  editClientObj: any;
  locationNames: string[]=[];
  givenlocationMessage: string="";

  constructor(
    private winRef: WindowRef,
    private route: ActivatedRoute,
     private _router: Router,
     private _oerService:OERService,
     private _tileNameService:TileNameService,
    private titleService: Title,
    private metaService: Meta,
    private _loaderService: LoaderService,
    private _metaService: MetaService,
    public dialogService: DialogService,
    private _dropDownService:DropdownService,
    private cdRef : ChangeDetectorRef,
    private  _translate: TranslateService
    )
    {
      this.dataoldObs$ = this.dataoldSubject$.asObservable();
      this.form = new FormGroup({
        creboName: new FormControl(null, [Validators.required]),
        cohortName: new FormControl(null),
        leerwegName: new FormControl(null, [Validators.required]),
        locatieName: new FormControl(null),
      });

    }
  ngAfterViewInit(): void {

  }
  ngAfterViewChecked(): void {


    if(this.startscroll && !this.hasScrolled)
    {
      this.hasScrolled=true;
      this.startscroll=false;
      this.myScrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }

  }
    ngOnInit(): void {

      this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
        const file = event.translations["DASHBOARD"];
        const oer = event.translations["OER"];
        const app = event.translations["APP"];
        this.setvalues(app, file,oer);
      });
      this.GetLanguageData();

      this._metaService.createCanonicalURL();

      this.route.queryParams.subscribe(params => {
       
        this.creboId = params['crebo']== undefined? 0:params['crebo'];
        this.leerwegId = params['leerweg'] == undefined? 0:params['leerweg'];
        this.cohortId = params['cohort']== undefined? 0:params['cohort'];
        this.locatieId=0;
        if(params['crebo']!==undefined)
          {
            this.loadfromparams=true
          }
        this.paramsCrebo = params['crebo'];
        if(this.creboId>0)
        {
          this.getOERdata(false);
        }



      });
      this._dropDownService.locatiedataObs$.subscribe( (ld)=>{
        this.locatiedata= ld;
      });
      this._dropDownService.datacreboObs$.subscribe(
        (c) =>{
          this.creboOptions=c
        }
      );
      this._dropDownService.dataleerwegObs$.subscribe(
        (options) =>{
          this.leerwegOptions=options
        }
      );
      this._dropDownService.dataperiodeObs$.subscribe(
        (options) =>{
          this.cohortOptions=options

        }
      );
      this._dropDownService.datalocatieObs$.subscribe(
        (options) =>{
          this.locationOptions=options
        }
      );
      // this.dataoldObs$.subscribe((d:boolean) => {
      //   if(d)
      //   {

      //     this.show = d;
      //     this.cdRef.detectChanges();

      //   }
      // });
       this._dropDownService.loaderState$.subscribe((state: ILoaderState) => {
          if (state.show != this.isLoading) {
            this.isLoading = state.show;
            this.cdRef.detectChanges();
          }
      });


      this.form.get('creboName')?.valueChanges.subscribe(
        val => {

          if(this.selectedCrebo!=+val)
          {
            if(!this.loadfromparams)
             this.resetOnderwijsleidraad();
            this.selectedCrebo = val;
          }
        }
      );
      this.form.get('cohortName')?.valueChanges.subscribe(
        val => {

          if(val==null || typeof val !== 'number')
            return;
         if(this.selectedCohort!=+val)
         {
          if(this.selectedCohort!=0 && !this.loadfromparams)
           this.resetOnderwijsleidraad();
          this.selectedCohort = val;
         }
        }
      );
      this.form.get('leerwegName')?.valueChanges.subscribe(

        val => {
         if(val==null || typeof val !== 'number')
          return;

          if(this.selectedLeerweg!=+val)
          {
            if(!this.loadfromparams)
              this.resetOnderwijsleidraad();
            this.selectedLeerweg = val;
          }
        }
      );
      this.form.get('locatieName')?.valueChanges.subscribe(
        val => {
          if(val==null || typeof val !== 'number')
          return;
          if(this.selectedLocatie!==+val)
          {
            if(!this.loadfromparams)
              this.resetOnderwijsleidraad();
              this.selectedLocatie = val;
            }
        }
      );
      this.tileNamesOphalen();
      this._dropDownService.getLocatieDataPublic();
      this._dropDownService.getLeerwegDataPublic();
     this._dropDownService.getPeriodeDataPublic();
      this._dropDownService.getCreboDataPulic();


    }


   get formX(): { [key: string]: AbstractControl; }
    {
        return this.form.controls;
    }

    private tileNamesOphalen(): void {
      let qo:QueryOptions= new QueryOptions();
      qo.path ="tiles" ;
      qo.fields ='name,code,color'
      this._oerService.listByPath(qo).subscribe({
        next: (response: ITileName[]) => {
          this.arrayTileNames = response;
        },
        error: () => {},
      //
      });
    }

    getTileName(fragementType: any, type: any) {
      if (this.arrayTileNames == null || this.arrayTileNames == undefined)
        return '';
      let ft = this.arrayTileNames.filter((l) => {
        return l.code == fragementType.toString();
      })[0]['name'];
      if (type == 1) ft = ft.replace('-', '\n').replace('-', '\n');


      return ft;
    }
    // Open van tile
    showTile(data:any):void {
      const t = data.FragmentType==80?TileTableComponent:TileComponent;
      const ref = this.dialogService.open(t, {
           data: {
              id: data.id,
              fragmentName:data.name,
              oer:true
          },
          dismissableMask:true,
          header: data.name,
          width: '90%'
      });
    }

    private resetOnderwijsleidraad():void {
      if (this.onderwijsleidraden.length > 0)
        {this.onderwijsleidraden = [];
         this.show = false;
         this.givenlocationMessage = '';
        }
    }
    onSubmit():void {
      if (this.form.pending) {
        this.form.statusChanges.subscribe(status => {
          if (status === 'VALID') {
            this.search();
          }
        });
      } else {
        if (this.form.valid) {
         this.search();
        }
      }

    }

    private getOERdata(isStudent:boolean) {
      this.hasScrolled=true;
      this._oerService.readold(this.creboId, 0, this.leerwegId,this.locatieId).pipe(delay(200)).subscribe((result: IOnderwijsleidraad[]) => {
        if (result != null) {
          this.onderwijsleidraden = [];
          if (result !== null) {
            // Sorteren van Tiles op door dienst onderwijs vaste volgorde
            Sort.Tiles(result);
            result.forEach((r: IOnderwijsleidraad) => {
              if(r.tilesarray!==undefined)
              {
                
                r.tilesarray.forEach((tile: any) => {
                  const tilename: string = this.getTileName(tile.fragmenttype, 1);
                  const color: string = this.arrayTileNames.filter((l) => {
                    return l.code == tile.fragmenttype;
                  })[0]['color'];
                  tile['color'] = color;
                  tile['name'] = tilename;
                });
              }
              if(r.locations!==undefined)
              {
                const t:any = (JSON.parse(r.locations) as any[])
                t.forEach((locatie: any) => {
                  const locatienaam: string = this.locatiedata.filter((l) => {
                    return l.id == locatie.LocatieID;
                  })[0]['name'];
                  this.locationNames.push (locatienaam);
                });
              }
            });
            if(this.loadfromparams)
            {
              const l:IDropdownOLD[] = this.leerwegOptions.filter((l)=>{return l.value==this.leerwegId});
              const c:IDropdownOLD[] = this.creboOptions.filter((l)=>{return l.value==this.creboId});
              this.form.patchValue({
                leerwegName:l,
                creboName: c
              });
            }
            this.onderwijsleidraden = result;
            this.show = false;
            this.hasScrolled=false;
            this.startscroll=true;
            this.givenlocationMessage = this.locationNames.length>0?this.locationNames.join(', '):'';
          }
        }
        else {
          this.dataoldSubject$.next(false);
          this.onderwijsleidraden = [];
          this.show = true;
        }
      });
    }


    public search():void
    {
      this.hasScrolled=false;
      const formModel = this.form.value;
      this.creboId =isNaN(+formModel.creboName)?0:+formModel.creboName;
      this.leerwegId =isNaN(+formModel.leerwegName)?0:+formModel.leerwegName;
      this.cohortId =isNaN(+formModel.cohortName)?0:+formModel.cohortName;
      this.locatieId =isNaN(+formModel.locatieName)?0:+formModel.locatieName;
      this.getOERdata(false);
    }

    private setvalues(app:any,search: any,oer:any) {
      this.titleService.setTitle('Onderwijs- en examenregelingen (OER)');
      this.CHOOSECREBO = oer['CHOOSECREBO'];
      this.CHOOSECOHORT = app['CHOOSECOHORT'];
      this.FOUND = app['FOUND'];
      this.VERSION = app['VERSION'];
      this.HEADER = search['HEADER'];
      this.RESULT = search['RESULT'];
      this.TAPTITLE = search['TAPTITLE'];
      this.TAPSEARCH = search['TAPSEARCH'];
      this.LOCATION = oer['LOCATION'];
      this.SELLOCATION = oer['SELLOCATION'];
      this.PATH = search['PATH'];
      this.SELPATH = oer['SELPATH'];
      this.SEARCH = oer['SEARCH'];
      this.INTRO = oer['INTRO'];
      this.NOTHINGFOUND = search['NOTHINGFOUND'];

    }

    private GetLanguageData() {
      const search = this._translate.instant('DASHBOARD');
      const oer = this._translate.instant('OER');
      const app = this._translate.instant('APP');
      this.setvalues(app, search,oer);
    }

    public onClear():void {
      if(this.show)
      this.show = false;
      this.onderwijsleidraden = [];
    }
}
