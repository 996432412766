<mat-nav-list >
  <div *ngFor="let link of items">
    <mat-divider *ngIf="link.divider!=null"></mat-divider>
    <h3 matSubheader *ngIf="link.header!=null">{{link.header}}</h3>
    <mat-list-item *ngIf="link.displayName!=null">
        <mat-icon *ngIf="link.iconName!=null" mat-list-icon>{{ link.iconName }}</mat-icon>
        
        <a *ngIf="!link.target" matLine routerLink="{{link.routerLink}}"
        routerLinkActive="active"
        (click)="test()"
        >{{link.displayName}}</a>

        <a *ngIf="link.target" matLine routerLink="{{link.routerLink}}"
        routerLinkActive="active"
        target="{{link.target}}"
        (click)="test()"
        >{{link.displayName}}</a>

    </mat-list-item>
  </div>
</mat-nav-list>