import { Pipe, PipeTransform } from '@angular/core';
import * as d3 from "d3";
@Pipe({
  name: 'datumconvert'
})
export class DatumconvertPipe implements PipeTransform {

  constructor()
  { 
     d3.formatDefaultLocale({
      "decimal": ",",
      "thousands": ".",
      "grouping": [3],
      "currency": ["€\u00a0", ""]
    });
    d3.timeFormatDefaultLocale({
      "dateTime": "%a %e %B %Y %T",
      "date":"%d-%m-%Y" ,
      "time": "%HH:%M:%S",
      periods: ['',''],
      "days": ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
      "shortDays": ["zo", "ma", "di", "wo", "do", "vr", "za"],
      "months": ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
      "shortMonths": ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]
    });
  }
  //woensdag 6 december 2017 21:25:38
  transform(value: any, args?: any): any {
    if("0001-01-01T00:00:00"=== value)
    return "Niet geavtiveerd";
    let formatAfter = d3.timeFormat("%A %e %B %Y %H:%M:%S");
    let formatTime = d3.timeFormat("%H:%M:%S");
    let formatSunTime = d3.timeFormat("%H:%M");
    let formatTimeSunShine = d3.timeFormat("%-H uur %-M min.");
    if(value && !isNaN(value) && args[0] === 't') {
      return formatTime(new Date(value));
    }
    if(value && !isNaN(value) && args[0] === 's') {
      return formatTimeSunShine(new Date(value));
    }
    if(value && !isNaN(value) && args[0] === 'z') {
      return formatSunTime(new Date(value));
    }
    return formatAfter(new Date(value));
  }
}
