import {Injectable} from '@angular/core';
import { ILanguage } from '../model/language.interface';

@Injectable()
export  class LanguageService {
  constructor() {}
  getLanguage(): ILanguage[] {
    return [
      { name: 'Engels', code: '1033' },
      { name: 'Dutch', code: '1043' },
      // { name: 'Germany', code: '1031' },
      // { name: 'French', code: '1036' },
      // { name: 'Norwegian', code: '1044' },
      // { name: 'Danish', code: '1030' },
      // { name: 'Italy', code: '1040' }
    ];
  }
  getLanguageTwoLetterCode(): ILanguage[] {
    return [
      { name: 'English', code: 'en' },
      { name: 'Dutch', code: 'nl' },
      { name: 'German', code: 'de' },
      { name: 'French', code: 'fr' },
      { name: 'Norwegian', code: 'nb' },
      { name: 'Danish', code: 'da' },
      { name: 'Italian', code: 'it' }
    ];
  }
}
