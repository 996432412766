<p-table *ngIf="showTable" #dt [value]="tableData" [loading]="loading" [columns]="cols" selectionMode="multiple">
  <ng-template pTemplate="caption">
      <div class="ui-helper-clearfix">
          <button title="{{ALL}}" type="button" pButton icon="pi pi-file-export" iconPos="left" label={{ALLDATA}} (click)="dt.exportCSV()" style="float:left"></button>
          <button  title="{{ONLYSELECTION}}" type="button" pButton icon="pi pi-file" iconPos="left" label={{ONLYSELECTION}} (click)="dt.exportCSV({selectionOnly:true})" style="float:right"></button>
      </div>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
      <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field">
              {{col.header}}
              <p-sortIcon [field]="col.field"></p-sortIcon>
          </th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-rowData let-columns="columns">
      <tr [pSelectableRow]="rowData">
          <td *ngFor="let col of columns">
              {{rowData[col.field]}}
          </td>
      </tr>
  </ng-template>
</p-table>
