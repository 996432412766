<ol *ngIf="lng!=='nl'">
    <li> Launch the <strong>Settings</strong> app on your iPhone or iPad running iOS/iPadOS 16.4 or later. </li>
    <li> Scroll down and navigate to <strong>Safari.</strong> </li>
    <li> Click <strong>Advanced</strong> and then <strong>Experimental Features</strong>.
        <div>
        <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings1.jpg" alt="Safari" width="20%">
        <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings2.jpg" alt="Geavanceerd" width="20%">
        <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings3.jpg" alt="Experimental Features" width="20%">
        </div>

    </li>
    <li> You will find a <strong>Push API</strong> toggle at the bottom of the list. Make sure it's enabled. </li>
    <li> Now launch the <strong>Safari</strong> app and visit filmfestkruh.de </li>
    <li> Tap on the <strong>Share</strong> button in the middle of the bottom bar </li>
    <li> Choose <strong>Add to Home Screen</strong>
    <div>
        <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings4.jpg" alt="Push API" width="20%">
        <!-- <img src="https://static1.xdaimages.com/wordpress/wp-content/uploads/wm/2023/02/img_2179.jpeg" alt="" width="20%">
        <img src="https://static1.xdaimages.com/wordpress/wp-content/uploads/wm/2023/02/img_2180.jpeg" alt="" width="20%"> -->
    </div>
    </li>
    <li> Optionally rename the web app, then tap <strong>Add</strong> in the top right corner. </li>
    <li> It will now appear on your Home Screen as a native app. Launch the newly-added web app. </li>
    <li> Find the website's notifications settings and enable push notifications. </li>
    <li> iOS will ask for your permission to enable them.
    </li>
</ol>
<ol *ngIf="lng=='nl'">
    <li>Start de app <strong>Instellingen</strong> op uw iPhone of iPad met iOS/iPadOS 16.4 of hoger. </li>
    <li>Scroll naar beneden en navigeer naar <strong>Safari.</strong> </li>
    <li>Klik op <strong>Geavanceerd</strong> en vervolgens op <strong>Experimentele functies</strong>.
        <div>
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings1.jpg" alt="Safari" width="20%">
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings2.jpg" alt="Geavanceerd" width="20%">
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings3.jpg" alt="Experimental Features" width="20%">
        </div>

    </li>
    <li>U vindt een <strong>Push API</strong>schakelaar onderaan de lijst. Zorg ervoor dat het is ingeschakeld.</li>
    <li>Start nu de <strong>Safari</strong> app en bezoek de website filmfestkruh.de.</li>
    <li>Tik op de knop  <strong>Delen</strong> in het midden van de onderste balk</li>
    <li>Kies <strong> Toevoegen aan startscherm</strong>
    <div>
        <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings4.jpg" alt="Push API" width="20%">
        <!-- <img src="https://static1.xdaimages.com/wordpress/wp-content/uploads/wm/2023/02/img_2179.jpeg" alt="" width="20%">
        <img src="https://static1.xdaimages.com/wordpress/wp-content/uploads/wm/2023/02/img_2180.jpeg" alt="" width="20%"> -->
    </div>
    </li>
    <li>Tik vervolgens op <strong>Toevoegen</strong> in de rechterbovenhoek.</li>
    <li>Het verschijnt nu op uw startscherm als een native app. Start de nieuw toegevoegde web-app.</li>
    <li>Zoek de meldingsinstellingen van de website en schakel pushmeldingen in.</li>
    <li>iOS zal uw toestemming vragen om ze in te schakelen.</li>
</ol>
<ol *ngIf="lng=='de'">
    <li>Starten Sie die App <strong>Einstellungen</strong> auf Ihrem iPhone oder iPad mit iOS/iPadOS 16.4 oder höher.</li>
    <li>Scrollen Sie nach unten und navigieren Sie zu<strong>Safari.</strong> </li>
    <li>Klicken Sie auf <strong>Erweitert</strong> und dann auf <strong>Experimentelle Funktionen</strong>
        <div>
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings1.jpg" alt="Safari" width="20%">
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings2.jpg" alt="Geavanceerd" width="20%">
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings3.jpg" alt="Experimental Features" width="20%">
        </div>

    </li>
    <li>Unten in der Liste finden Sie einen <strong>Push API</strong>-Schalter. Stellen Sie sicher, dass es eingeschaltet ist.</li>
    <li>Starten Sie jetzt die <strong>Safari</strong>-App und besuchen Sie die Website filmfestkruh.de.</li>
    <li>Tippen Sie auf die Schaltfläche <strong>Teilen</strong> in der Mitte der unteren Leiste</li>
    <li>Wählen Sie <strong> Zum Startbildschirm hinzufügen</strong>
    <div>
        <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings4.jpg" alt="Push API" width="20%">
        <!-- <img src="https://static1.xdaimages.com/wordpress/wp-content/uploads/wm/2023/02/img_2179.jpeg" alt="" width="20%">
        <img src="https://static1.xdaimages.com/wordpress/wp-content/uploads/wm/2023/02/img_2180.jpeg" alt="" width="20%"> -->
    </div>
    </li>
    <li>Tippen Sie dann oben rechts auf <strong>Hinzufügen</strong>.</li>
    <li>Es erscheint nun als native App auf Ihrem Startbildschirm. Starten Sie die neu hinzugefügte Web-App.</li>
    <li>Suchen Sie nach den Benachrichtigungseinstellungen der Website und aktivieren Sie Push-Benachrichtigungen.</li>
    <li>iOS wird Sie um Erlaubnis bitten, sie zu aktivieren.</li>
</ol>
<ol *ngIf="lng=='fr'">
    <li>Lancez l'application <strong>Paramètres</strong> sur votre iPhone ou iPad avec iOS/iPadOS 16.4 ou version ultérieure.</li>
    <li>Faites défiler vers le bas et accédez à <strong>Safari.</strong> </li>
    <li>Cliquez sur <strong>Avancé</strong>, puis cliquez sur <strong>Fonctionnalités expérimentales</strong>
        <div>
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings1.jpg" alt="Safari" width="20%">
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings2.jpg" alt="avancé" width="20%">
            <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings3.jpg" alt="Fonctionnalités expérimentales" width="20%">
        </div>

    </li>
    <li>Au bas de la liste se trouve un commutateur <strong>Push API</strong>. Assurez-vous qu'il est allumé.</li>
    <li>Démarrez l'application <strong>Safari</strong> maintenant et visitez le site Web filmfestkruh.de.</li>
    <li>Appuyez sur le bouton <strong>Partager</strong> au milieu de la barre inférieure</li>
    <li>Sélectionnez <strong> Ajouter à l'écran d'accueil</strong>
    <div>
        <img src="https://meteo0251.blob.core.windows.net/ios/ios_settings4.jpg" alt="Push API" width="20%">
        <!-- <img src="https://static1.xdaimages.com/wordpress/wp-content/uploads/wm/2023/02/img_2179.jpeg" alt="" width="20%">
        <img src="https://static1.xdaimages.com/wordpress/wp-content/uploads/wm/2023/02/img_2180.jpeg" alt="" width="20%"> -->
    </div>
    </li>
    <li>Appuyez ensuite sur <strong>Ajouter</strong> dans le coin supérieur droit.</li>
    <li>Il apparaîtra maintenant comme une application native sur votre écran d'accueil. Lancez l'application Web nouvellement ajoutée.</li>
    <li>Recherchez les paramètres de notification du site Web et activez les notifications push.</li>
    <li>iOS vous demandera la permission de les activer.</li>
</ol>
