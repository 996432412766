import { MsalInterceptorConfiguration, ProtectedResourceScopes } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { environment } from "src/environments/environment";

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
     const protectedResourceMap = new Map<string,  Array<string | ProtectedResourceScopes> | null>([
      [ environment.resources.onderwijsleidraadApi.resourceWs  + '/ws*',null],
      [ environment.resources.onderwijsleidraadApi.resourceWs  + '/datapipe'      ,null],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/tilename'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/crebo'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/period'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/student*'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/element'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/fragment*'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/employee*'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/onderwijsleidraad'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/sbb'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/location'  ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/webpush/notify',[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/webpush/count' ,[environment.resources.onderwijsleidraadApi.resourceScope] ],
      [ environment.resources.onderwijsleidraadApi.resourceUri + '/webpush'       ,null],
      [ environment.resources.onderwijsleidraadApi.resourceUri,[{
              httpMethod: "POST",
              scopes: [ environment.resources.onderwijsleidraadApi.resourceScope]

          },
          {
              httpMethod: "PATCH",
              scopes: [ environment.resources.onderwijsleidraadApi.resourceScope]
          },
          {
              httpMethod: "GET",
              scopes: [ ]
          }
          ]
      ] 
    ]);


    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap:  protectedResourceMap,
    };
  }