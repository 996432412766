import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource.service';
import { Injectable } from '@angular/core';
import { ICrebo } from '../../model/crebo.interface';
import { ConfigService } from '../../config.service';
import { AlertService } from '../alert.service';
@Injectable()
export class CreboService extends ResourceService<ICrebo> {
  constructor(httpClient: HttpClient,
    _alertServices:AlertService,
    _configService: ConfigService) {
    super(
      httpClient,
      _configService.apiAzure,
      _alertServices,
      'crebo'
      );
  }
}
