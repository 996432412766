import { Component } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'nova-iphone-push',
  templateUrl: './iphone-push.component.html',
  styleUrls: ['./iphone-push.component.css']
})
export class IphonePushComponent {
  lng:string|undefined='nl';
  constructor(private _translate: TranslateService) {
    this._translate.onLangChange.subscribe((event:TranslationChangeEvent) => {
      this.lng = event.lang;
    });
      this.lng = this._translate.getBrowserLang();
   }
}
