<div>
  <div>
      <p>
          Reason: {{config.data.reason}}
      </p>
      <p>
          Status: {{config.data.status}}
      </p>
  </div>
</div>
