import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';


import { ISqlChanged } from 'src/app/core/model/sqlchanged.interface';


export interface StoreStateCounter {
  counter:number;
}

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  connection!: signalR.HubConnection;
  hub!: BehaviorSubject<ISqlChanged>;
  // hubLink: BehaviorSubject<LinkData>;
  // hubProgram: BehaviorSubject<ProgramData>;
  // hubContent: BehaviorSubject<EditorialData>;
  hubImage: BehaviorSubject<string>;
  hubPing!: BehaviorSubject<string|null>;
  hubCounterSubject = new BehaviorSubject<number>(0);
  private isConnectedSubject = new Subject<boolean>();
  public  ObservableConnected$!:Observable<boolean>;
  public  ObservableCounter$!:Observable<number>;
  state!: signalR.HubConnectionState;

  constructor() {


      this.ObservableConnected$ = this.isConnectedSubject.asObservable();
      this.hub = new BehaviorSubject<ISqlChanged>({});
    // this.hubLink = new BehaviorSubject<LinkData>(null);
    // this.hubProgram = new BehaviorSubject<ProgramData>(null);
    // this.hubContent = new BehaviorSubject<EditorialData>(null);
    this.hubImage = new BehaviorSubject<string>('');
    this.hubPing = new BehaviorSubject<string|null>(null);
    this.ObservableCounter$ = this.hubCounterSubject.asObservable();
  }

  // Establish a connection to the SignalR server hub
  public initiateSignalrConnection(): Promise<void>{
    const url =`https://${environment.domainapi}${environment.domainapiAppPath}${environment.signalrHubUrl}`
    return new Promise((resolve, reject) => {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(url)
        .withAutomaticReconnect()
        .configureLogging(
          !environment.production?signalR.LogLevel.Information:signalR.LogLevel.None
          )
        .build();

      this.connection
        .start()
        .then(() => {
          // this.setSignalrClientMethods();
          this.startStreaming()
          !environment.production?console.log(`SignalR connection success! connectionId: ${this.connection.connectionId} `):null;
          this.isConnectedSubject.next(true);
          resolve();
        })
        .catch((error) => {
          console.log(`SignalR connection error: ${error}`);
          this.isConnectedSubject.next(false);
          resolve();
        });
      this.state = this.connection.state;
      this.connection.onreconnected(connectionId => {
        !environment.production? console.log(`SignalR onreconnected success! connectionId: ${connectionId} `):null;
        this.startStreaming()
        });
      this.connection.onreconnecting(connectionId => {
        this.isConnectedSubject.next(true);
        });
    });
  }

public startStreaming() {
    this.connection.on('ping',data=>{
      this.hubPing.next(data);
    });

    this.connection.on('ImageUrlMessage',img=>{
      if(img!==null)
      this.hubImage.next(img);
    });
  // this.connection.send("SendImageUrlMessage","test");
  // this.connection.invoke("SendOnline");
  this.connection.stream("realtimeData").subscribe({
      next: (model) => {
        if(model!== null)
        {
          this.hub.next(model);

          // if(model.link!==null)
          // this.hubLink.next(model?.link);
          // if(model.program!==null)
          // this.hubProgram.next(model?.program);
          // if(model.content!==null)
          // this.hubContent.next(model?.content);
        }
      },
      error: () => {
      },
      complete: () => {

      }
  });
}

  public stopSignalrConnection()
  {
    if(this.connection!==undefined)
    this.connection.stop()
  }
}
