<mat-card class="card-margin">
  <mat-card-header class="kop">
    <mat-card-title class="title">{{HEADER}}</mat-card-title>
  </mat-card-header>
<mat-card-content>
<form  [formGroup]="form" class="example-form">
<p-autoComplete formControlName="myControl"
placeholder="{{PHSEARCH}}" 
[dropdown]="true"
[suggestions]="results" 
[showClear]="true" [size]="50" (onClear)="ACclear()" (onSelect)="ACSelect($event)" (completeMethod)="search($event)"></p-autoComplete>
<!-- <mat-form-field>
     <input type="text" placeholder="{{PHSEARCH}}"
     [matAutocomplete]="auto"
     formControlName="myControl"
     matInput /><br />
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let userAccount of filteredOptions | async" >
        </mat-option>
    </mat-autocomplete> 
</mat-form-field > -->
</form>
<div *ngIf="!environment.production">
<pre>Form values: {{ form.value | json }}</pre>
<pre>Valid form? {{ form.valid | json }}</pre>
<pre>Valid pristine? {{ form.pristine | json }}</pre>
</div>
<div *ngIf="show">
{{RESULT}}<br/>
<div *ngFor="let o of onderwijsleidraden" style="margin-top:10px">
  <p-fieldset legend="{{o.name}}, {{VERSION}}{{o.changed*1000| date:' EEEE, d MMMM yyyy HH:mm'}}">
    <div *ngFor="let g of o.tilesarray" style="margin-top: 10px">
      <div  [ngClass]="{ 'tegelnew':checked,'tegel':!checked }" (click)="showTile(g)" [style.background-color]="!checked?g.color:''" [style.border-color]="checked?g.color:''" [style.border-color]="checked? g.color:''" [style.color]="checked? g.color:'#FFF'">
        <span>{{g.name}}</span>
      </div>
    </div>
    </p-fieldset>
</div>
</div>
<h3 *ngIf="onderwijsleidraden.length==0 && show">{{NOTHINGFOUND}} {{searchtext}}(</h3>
</mat-card-content>
</mat-card>