<div class="animated slideInRight">
  <mat-tab-group (selectedTabChange)="tabChanged($event)">
      <mat-tab label="{{TAPTITLE}}">
        <mat-card-content>
        <form [formGroup]="form" (submit)="onSubmit()" novalidate>
            <div fxLayout.lt-xl="column" fxLayout.gt-lg="row" fxLayoutGap="20px">
              <div flex=25>
                <label for="creboName" class="required">Crebo</label>
                   <p-dropdown formControlName="creboName" [showClear]="true" placeholder="{{CHOOSECREBO}}"
                   optionLabel="label" optionValue="value" [options]="creboOptions" [filter]="true" filterBy="label,value"
                   [ngClass]="(form.invalid && (form.dirty || form.touched) && creboName?.errors?.['required'])? 'has-error': ''"
                   ></p-dropdown>
              </div>
              <div flex=25>
                <label for="leerwegName">{{PATH}}</label>
                   <p-dropdown formControlName="leerwegName" [showClear]="true" placeholder="{{SELPATH}}"
                   optionLabel="label" optionValue="value" [options]="leerwegOptions" [filter]="true" filterBy="label,value"></p-dropdown>
              </div>
              <div flex=25>
                <label for="leerwegName">Cohort</label>
                <p-dropdown formControlName="cohortName" [showClear]="true" placeholder="{{CHOOSECOHORT}}"
                optionLabel="label" optionValue="value" [options]="cohortOptions" [filter]="true" filterBy="label,value"></p-dropdown>
              </div>
              <div flex=25>
                <label for="leerwegName">{{LOCATION}}</label>
                <p-dropdown formControlName="locatieName" [showClear]="true" placeholder="{{SELLOCATION}}"
                optionLabel="label" optionValue="value" [options]="locationOptions" [filter]="true" filterBy="label,value" ></p-dropdown>
              </div>
            </div>
            <div fxLayout.gt-lg="row" fxLayout.lt-xl="column">
              <p-button type="submit" class="searchbtn" [disabled]="form.pristine || form.invalid" >
                {{TAPSEARCH}}
              </p-button>
            </div>
        </form>
      <div *ngIf="onderwijsleidraden.length > 0" >
      <span *ngIf="!isyours">{{RESULT}} {{onderwijsleidraden.length}} {{FOUND}}</span>
        <div *ngFor="let o of onderwijsleidraden">

          <p-fieldset legend="{{o.name}}, {{VERSION}}{{o.changed*1000| date:' EEEE, d MMMM yyyy HH:mm'}}" [toggleable]="onderwijsleidraden.length > 1" [collapsed]="onderwijsleidraden.length > 1">
                    <div *ngFor="let g of o.tilesarray">
                <div  [ngClass]="{ 'tegelnew':checked,'tegel':!checked }" (click)="showTile(g)" [style.background-color]="!checked?g.color:''" [style.border-color]="checked?g.color:''" [style.border-color]="checked? g.color:''" [style.color]="checked? g.color:'#FFF'">
                  <span>{{g.name}}</span>
                </div>
            </div>
            </p-fieldset>
        </div>
      </div>
        <h3 *ngIf="onderwijsleidraden.length==0 && show">{{NOTHINGFOUND}}</h3>
          <div *ngIf="!environment.production">
              <pre>Form values: {{ form.value|json}}</pre>
              <pre>Valid form? {{ form.valid|json}}</pre>
              <pre>Valid pristine? {{ form.pristine | json }}</pre>
          </div>
          <div #scrollMe>.</div>
        </mat-card-content>
        </mat-tab>
    <mat-tab label="{{TAPSEARCH}}">
      <old-search></old-search>
    </mat-tab>
  </mat-tab-group>

