export interface QueryBuilder {
  toQueryMap: () => Map<string, string>;
  toQueryString: () => string;
}
enum Language {de = 1031,en = 1033, all=0};


export class QueryOptions implements QueryBuilder {
  public pageNumber: number;
  public pageSize: number;
  public id!: number;
  public lcidnr!: number|null;
  public type!: string|number|undefined;
  public lcid!: string|null;
  public year!: number;
  public required!: number;
  public fields!:string|undefined;
  public path!:string;
  public name!:string;
  public ids!:string;
  public search!:string;
  public small!: number;
  public fragementType:number|undefined;
  public stamnr!: number;
  constructor() {
    this.pageNumber = 0;
    this.pageSize = 0;
  }

  toQueryMap() {
    const pathMap = new Map<string, string>();

    if(this.lcidnr!=undefined && this.lcidnr!=null)
    {pathMap.set('lcidnr', `${this.lcidnr}`);}
    if(this.lcid!=undefined && this.lcid!=null)
    {
      let lcid_enum: Language = (<any>Language)[this.lcid];
      pathMap.set('lcid', `${lcid_enum}`);
    }
    if(this.path!=undefined && this.path!=null)
    {
      pathMap.set('path', `${this.path}`);
    }
    if(this.id!=undefined && this.id!=null)
    {
      pathMap.set('id', `${this.id}`);
    }
    if(this.type!=undefined && this.type!=null)
    pathMap.set('type', `${this.type}`);
    if(this.stamnr>0)
    pathMap.set('stamnr', `${this.stamnr}`);
    if(this.year!=undefined && this.year!=null)
    {pathMap.set('year', `${this.year}`);}
    if(this.fields!=undefined && this.fields!=null)
    pathMap.set('fields', `${this.fields}`);
    if(this.search!=undefined && this.search!=null)
    pathMap.set('search', `${this.search}`);
    if(this.name!=undefined && this.name!=null)
    pathMap.set('name', `${this.name}`);
    if(this.small!=undefined && this.small!=null)
    pathMap.set('small', `${this.small}`);
    if(this.ids!=undefined && this.ids!=null)
    pathMap.set('ids', `${this.ids}`);
  

    return pathMap;
  }
  toPathMap() {
    const queryMap = new Map<string, string>();
    if(this.pageNumber>0)
    queryMap.set('pageNumber', `${this.pageNumber}`);
    if(this.pageSize>0)
    queryMap.set('pageSize', `${this.pageSize}`);
    if(this.type!=undefined && this.type!=null)
    queryMap.set('type', `${this.type}`);
    if(this.fields!=undefined && this.fields!=null)
    queryMap.set('fields', `${this.fields}`);
    if(this.lcid!=undefined && this.lcid!=null)
    {
      var lcid_enum: Language = (<any>Language)[this.lcid];
      queryMap.set('lcid', `${lcid_enum}`);
    }
    if(this.id!=undefined && this.id!=null)
    {
      queryMap.set('id', `${this.id}`);
    }
    if(this.lcidnr!=undefined && this.lcidnr!=null )
    {
      queryMap.set('lcid', `${this.lcidnr}`);
    }
    if(this.type!=undefined && this.type!=null)
    queryMap.set('type', `${this.type}`);
    if(this.search!=undefined && this.search!=null)
    queryMap.set('search', `${this.search}`);
    if(this.year!=undefined && this.year!=null)
    {queryMap.set('year', `${this.year}`);}

    if(this.small!=undefined && this.small!=null)
    queryMap.set('small', `${this.small}`);
    if(this.required!=undefined && this.required!=null)
    queryMap.set('required', `${this.required}`);
    if(this.fragementType!=undefined)
    {
      queryMap.set('fragementType', `${this.fragementType}`);
    }

    return queryMap;
  }

  toQueryString() {
    let queryString = '?';
    this.toQueryMap().forEach((value: string, key: string) => {
      queryString = queryString.concat(`${key}=${value}&`);
    });

    return queryString.substring(0, queryString.length - 1);
  }
  toContentQueryString() {
    let queryString = '?';
    this.toQueryMap().forEach((value: string, key: string) => {
      queryString = queryString.concat(`${key}=${value}&`);
    });

    return '/content'+ queryString.substring(0, queryString.length - 1);
  }
  toPathString() {
    let queryString = '';
    this.toQueryMap().forEach((value: string, key: string) => {
      if(key!=="fields" && key!=='ids')
      queryString = queryString.concat(`/${value}`);
    });
    this.toQueryMap().forEach((value: string, key: string) => {
      if(key==="fields")
      {
        queryString = queryString.concat(`?${key}=${value}`);
      }
      if(key==='ids')
      {
        queryString = queryString.concat(`&${key}=${value}`);
      }
    });
    return queryString;
  }
}
