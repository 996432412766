import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource.service';
import { Injectable } from '@angular/core';
import { AlertService } from '../alert.service';
import { ConfigService } from '../../config.service';
import { ISBB } from '../../model/sbb.interface';
@Injectable()
export class SSBService extends ResourceService<ISBB> {
  constructor(
    httpClient: HttpClient,
    _alertServices:AlertService,
   _configService: ConfigService) {
    super(
      httpClient,
      _configService.apiAzure,
      _alertServices,
      'sbb'
      );
  }
}
