import { Injectable } from '@angular/core';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

@Injectable()
export class ConfigService {
  private _config:any = environment.config;
  private _regex:any = environment.regex;
  snackConfig = new MatSnackBarConfig();
  apiVersion="v1";
  apiAzure = `https://${environment.domainapi}${environment.domainapiAppPath}/${this.apiVersion}/`;
  httpCacheDuration = 9800; // 1 seconds
  httpDelay = 1; // http delay

  apiUrl=`https://${environment.domainapi}${environment.domainapiAppPath}/`;
  constructor() {
    this.snackConfig.duration = 2500;
    this.snackConfig.panelClass = ['snack'];
  }
  get(key:any){
      return this._config[key];
  }
  getRegex(key:any){
    return this._regex[key];
  }

}
