export class SortData{
    // Sorteren van api data
  public static By(data: any[], property: string) {
    return data
    .sort((a: any, b: any) => {
      if (a[property] < b[property]) {
        return -1;
      }
      if (a[property] > b[property]) {
        return 1;
      }
      return 0;
    });
  }
  public static ByDesc(data: any[], property: string) {
    return data
    .sort((a: any, b: any) => {
      if (a[property] < b[property]) {
        return 1;
      }
      if (a[property] > b[property]) {
        return -1;
      }
      return 0;
    });
  }
}