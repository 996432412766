import { HttpClient } from '@angular/common/http';
import { ResourceService } from './resource.service';
import { Injectable } from '@angular/core';
import { ConfigService } from '../../config.service';
import { AlertService } from '../alert.service';
import { ILocatie } from '../../model/locatie.interface';

@Injectable()
export class LocatieService extends ResourceService<ILocatie> {
  constructor(
    httpClient: HttpClient,
    _alertServices:AlertService,
    private configService: ConfigService) {
    super(
      httpClient,
      configService.apiAzure,
      _alertServices,
      'location'
      );
  }
}
