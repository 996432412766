import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  map, Observable } from 'rxjs';
import { ConfigService } from '../config.service';

@Injectable()
export class FileService {
  constructor(private http: HttpClient,
    private configService: ConfigService) {}

  upload(formData: FormData, containername?:string) {
    const url:string= containername?`${this.configService.apiAzure}file/${containername}`:`${this.configService.apiAzure}file`;
    return this.http.post<{ path: string[] }>(
        url,
      formData, {reportProgress: true, observe: 'events'}
    );
  }

  downloadFile(id: string,containername:string,contenttype?:string): Observable<any> {

    let url:string= containername?`${this.configService.apiAzure}file?file=${id}&containername=${containername}`:`${this.configService.apiAzure}file/${id}`;
    if(contenttype)
    {
      url = `${url}&contenttype=${encodeURIComponent(contenttype)}`;
    }
    else{
      contenttype = "application/pdf"
    }
    return this.http.get(url, {
        responseType: 'blob',
        observe: 'response'
    })
        .pipe(
            map((res: any) => {
                return new Blob([res.body], { type: contenttype });
            })
        );
    }
}