import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms';
import { DatumconvertPipe } from './datumconvert.pipe';

// import { MatAutocompleteModule } from '@angular/material/autocomplete';
// import { MatButtonModule } from '@angular/material/button';
// import { MatButtonToggleModule } from '@angular/material/button-toggle';
// import { MatCardModule } from '@angular/material/card';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatDialogModule } from '@angular/material/dialog';
// import { MatGridListModule } from '@angular/material/grid-list';
// import { MatIconModule } from '@angular/material/icon';
// import { MatInputModule } from '@angular/material/input';
// import { MatListModule } from '@angular/material/list';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatPaginatorModule } from '@angular/material/paginator';
// import { MatProgressBarModule } from '@angular/material/progress-bar';
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MatRadioModule } from '@angular/material/radio';
// import { MatSelectModule } from '@angular/material/select';
// import { MatSidenavModule } from '@angular/material/sidenav';
// import { MatSort, MatSortModule } from '@angular/material/sort';
// import { MatTableModule } from '@angular/material/table';
// import { MatTabsModule } from '@angular/material/tabs';
// import { MatToolbarModule } from '@angular/material/toolbar';
// import { MatTooltipModule } from '@angular/material/tooltip';

import { BlurForwarderDirective } from '../directive/blur-forwarder.directive';
import { PrimeNGModule } from './primeng.modules';
import { FlexLayoutModule } from '@angular/flex-layout';
// import { CreboListComponent } from '../components/OLD/crebo/crebo-list.component';
// import { LeerwegListComponent } from '../components/OLD/leerweg/leerweg-list.component';
// import { PeriodeListComponent } from '../components/OLD/periode/periode-list.component';
// import { StartComponent } from '../dashboard/start/start.component';
// import { TileComponent } from '../components/OLD/tile/tile.component';
// import { LocatieComponent } from '../components/OLD/locatie/locatie.component';
// import { CountInfoComponent } from '../components/OLD/countinfo/count-info.component';
// import { SSBService } from '../core/services/ssb.service';
// import { TileTableComponent } from '../components/OLD/tile/keuzedelentabel/tile-table.component';
// import { DropdownService } from '../core/services/dropdown.services';
// import { TegelComponent } from '../components/OLD/tegel/tegel.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faLock, faBell, faPause,faPlay,faBellSlash, faAt,faEnvelope, faPrint, faStop, faSearch, faInfo, faPencil, faPlus, faCheck, faClose } from '@fortawesome/free-solid-svg-icons';
import { DialogService } from 'primeng/dynamicdialog';
import { HrefExternalUrlDirective } from '../directive/href-external-url.directive';
import { LoaderService } from '../core/loader/loader.service';
import { PreventDoubleClickDirective } from '../directive/preventdouble-click.directive';
import { PreventDoubleSubmitDirective } from '../directive/preventdouble-submit.directive';
import { IphonePushComponent } from '../components/info/iphone-push/iphone-push.component';
import { NoWebpushComponent } from '../components/info/no-webpush/no-webpush.component';

const modules = [
   CommonModule,
   FormsModule,
   ReactiveFormsModule,
  //  MatSidenavModule,
  //  MatCardModule,
  //  MatButtonModule,
  //  MatCheckboxModule,
  //  MatInputModule,
  //  MatAutocompleteModule,
  //  MatListModule,
  //  MatSelectModule,
  //  MatProgressSpinnerModule,
  //  MatProgressBarModule,
  //  MatSelectModule,
  //  MatTabsModule,
  //  MatToolbarModule,
  //  MatTableModule,
  //  MatDialogModule,
  //  MatGridListModule,
  //  MatTooltipModule, MatRadioModule,MatButtonToggleModule,MatIconModule,MatPaginatorModule,
  //  MatSortModule,
   PrimeNGModule,
   FlexLayoutModule,
  //  MatMenuModule,
   FontAwesomeModule
];

@NgModule({
  imports: [modules],
  exports: [ modules,
    DatumconvertPipe,
    BlurForwarderDirective,
    HrefExternalUrlDirective,
    PreventDoubleClickDirective,
    PreventDoubleSubmitDirective,
    IphonePushComponent
    // CreboListComponent,
    // LeerwegListComponent,
    // PeriodeListComponent,
    // LocatieComponent,
    // TegelComponent
  ],
  declarations: [
   DatumconvertPipe,
   BlurForwarderDirective,
   PreventDoubleClickDirective,
   PreventDoubleSubmitDirective,
   IphonePushComponent,
   NoWebpushComponent,
  //  CreboListComponent,
  //  LeerwegListComponent,
  //  PeriodeListComponent,
  //  LocatieComponent,
  //  TileComponent,
  //  TileTableComponent,
  //  TegelComponent,
   HrefExternalUrlDirective
  ],
  providers:[
    DatumconvertPipe,
    DialogService,
    // LoaderService
    // SSBService,
    // DropdownService
  ],
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    // Add an icon to the library for convenient access in other components
      library.addIcons(faCheck,faClose,faPrint,faEnvelope,faPause,faStop,faPlay, faSearch,faAt,faInfo,faBell,faBellSlash,faLock,faPlus,faPencil);
    }

    // library.add(faLock,faEllipsisH,faTelegram,faMix,faPause,faVolumeUp,faVolumeDown,faVolumeMute,
    //   faVk,faTelegramPlane,faBell,faBellSlash,faPlay,faStop,
    //   faFacebookMessenger,faAt,faDownload,faSync,faUserShield,
    //   faXing,faTicketAlt,faCheck,faAngleDoubleRight,faCaretDown,
    //   faLine,faPrint,faCommentAlt,faEnvelope,faLink,faMinus,faCircleNotch,
    //   faWhatsapp,faTumblr,faTwitter,faFacebookSquare,faFacebookF,faVimeo,faMusic,faTwitterSquare,faPinterestP,faSnapchatSquare,faLinkedinIn,faRedditAlien,faGooglePlusG);
  // }
 }
