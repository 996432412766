import { IDropdown } from "../core/model/dropdown.interface";

export abstract  class KeuzedeelTypen {
  GetStartEnd!: Function;
  keuzedeelType: IDropdown[]=[];
  public static GetStartEnd(): any
  {
   return  [
      {name: '1e jaar', code: '0'},
      {name: '2e jaar', code: '1'},
      {name: '3e jaar', code: '2'},
      {name: '4e jaar', code: '4'}
    ];
  }
  public static GetKeuze(): any
  {
   return  [
      {name: 'Begeleide onderwijstijd', code: '0'},
      {name: 'Stage', code: '1'},
      {name: 'Zelfstudie', code: '2'}
    ];
  }
  public static GetGegevenIn(): any
  {
   return  [
      {name: 'Standaard', code: '0'},
      {name: 'vrije keuze', code: '1'},
      {name: 'onderdeel van pakket 1', code: '2'},
      {name: 'onderdeel van pakket 2', code: '4'},
      {name: 'onderdeel van pakket 3', code: '8'},
      {name: 'onderdeel van pakket 4', code: '16'},
      {name: 'onderdeel van pakket 5', code: '32'},
      {name: 'onderdeel van pakket 6', code: '64'}
    ];
  }
  // public static GetKeuzeNameByCode(code:number)
  // {
  //   if(code==undefined)
  //     return "";
  //    return KeuzedeelTypen.GetKeuze().filter(l=>{return l.code==code.toString()})[0].name;
  // }
  public static GetKeuzeNameByCode(code:number[]|undefined)
  {
    if(code==undefined)
    return "";
    return KeuzedeelTypen.GetKeuze().filter( (o:any) => {return code.includes(+o.code)} ).map( (o:any) => o.name)
    .reduce((g:string, name:string) => {
     g +=`${name} \n`;
     return g;
   }, "");
  }
  public static  GetKeuzeInByCodes(code:number[]|undefined)
  {
    if(code==undefined)
    return "";
    return KeuzedeelTypen.GetGegevenIn().filter( (o:any) => {return code.includes(+o.code)} ).map( (o:any) => o.name)
    .reduce((g:string, name:string) => {
     g +=`${name} \n`;
     return g;
   }, "");
  }
  public static GetStartByCodes(code:number[]|undefined)
  {
    if(code==undefined)
    return "";
     return KeuzedeelTypen.GetStartEnd().filter( (o:any) => {return code.includes(+o.code)} ).map( (o:any) => o.name)
     .reduce((g:string, name:string) => {
      g +=`${name} \n`;
      return g;
    }, "");
  }


}
