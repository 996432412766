import { Injectable } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { Observable, Subject, debounceTime, distinctUntilChanged, filter } from "rxjs";
import { EventMessage, EventType } from "@azure/msal-browser";
import { IApiState } from "./apitoken/Iapistate";
import { IStudent } from "../model/student.interface";
import { QueryOptions } from "./query.options";
import { ApiTokenService } from "./apitoken/api-token.service";
import { AppInsightsService } from "./app-insights.service";
import { StudentService } from "./api/student.services";
@Injectable()
export class AuthService {
  private name!:string;
  loginDisplay: boolean=false;
  private apiSubject = new Subject<IApiState>();
  apiStudent$:Observable<IApiState>;
  username: string="?";
    constructor(
       private _authService: MsalService,
       private msalBroadcastService: MsalBroadcastService,
       private _apiService:ApiTokenService,
       private _studentService:StudentService,
       private _appInsightsService: AppInsightsService
       ) {
        this.apiStudent$ = this.apiSubject.asObservable();

        this._apiService.apiRole$.pipe(
          debounceTime(500),
          distinctUntilChanged()).subscribe((hasToken:IApiState) => {
         // Alleen voor studenten van toepassing
          if(hasToken.hasStudentIfo)
          { 
            const naam = this.getNovaId();
            //   naam = '140140@novacollege.nl';
            var reg = new RegExp('^[0-9]+$');
            if(reg.test(naam))
            { hasToken.hasStudentIfo=true;
              hasToken.isAdmin=false;
              this.apiSubject.next(hasToken);
              const stamnr= +naam;
              let o: QueryOptions = new QueryOptions();
              o.stamnr = stamnr;
              this._studentService.list(o).subscribe((student: IStudent[]) => {
                if(student!== undefined)
                {
                  let s:IStudent= {
                    id_leerweg:student[0].id_leerweg,
                    id_location:student[0].id_location,
                    crebo:student[0].crebo,
                    period:student[0].period,
                    id:stamnr
                  };
                  localStorage.setItem(naam,JSON.stringify(s));
                  this._appInsightsService.logEvent("User",{
                    "Stamnr":naam,
                    "Crebo":s.crebo.toString(),
                    "Cohort":s.period.toString(),
                    "Leerweg":s.id_leerweg.toString(),
                    "Locatie":s.id_location.toString(),
                  });
                }
              });
              this._appInsightsService.setAuthenticatedUserId(naam);
            }
          }
        });
        this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => {return (msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS|| msg.eventType === EventType.LOGIN_SUCCESS)}),
        )
        .subscribe((e:any) => {
          if(e!=null && e.payload.account!=null)
          {
            this.name = e.payload.account.name;
            this.username = e.payload.account.username.toUpperCase();
            this.loginDisplay =true;
            this._authService.instance.setActiveAccount(e.payload.account);
          }
        });
     }

  public isLoggedIn():boolean {
    return this._authService.instance.getAllAccounts().length > 0;
  }

  public signout():void {
   this._authService.logout()
  }

  public startAuthentication():any {
    this._authService.loginRedirect();
  }
  public getUserName():string{
    if(this.username!==undefined)
      return this.username;
    else
      return "?";
  }

  public getName(): string {
    if(this.name!==undefined)
    {
      const n:string[]  = this.name.split(', ');
      if(n[1]==undefined)
        this.name= n[0];
      else
        this.name= n[1] +' '+ n[0];
      return  this.name;
    }
    else
    return "";
  }
  public getNovaId(): string {

     if(this.username!==undefined)
     {
      return this.username.indexOf('@')!==-1 ? this.username.split('@')[0]:this.username;
     }
     else
       return "";
  }
}
