import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, timer, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { Injectable } from "@angular/core";

/**
 * Custom preloading strategy for the application.
 * Implements the PreloadingStrategy interface.
 */
@Injectable()
export class AppPreloadingStrategy implements PreloadingStrategy {
    /**
     * Preloads the specified route if it has the 'preload' flag set in its data.
     * @param route - The route to preload.
     * @param load - The function to load the route.
     * @returns An Observable that emits the result of the preload operation.
     */
    preload(route: any, load: Function): Observable<any> {
        const loadRoute = (delay:number) => delay
            ? timer(15000).pipe(flatMap(_ => load()))
            : load();
        return route.data && route.data.preload
            ? loadRoute(route.data.delay)
            : of(null);
    }
}
