import { Component } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'OLD-no-webpush',
  templateUrl: './no-webpush.component.html',
  styleUrls: ['./no-webpush.component.css']
})
export class NoWebpushComponent  {
  TITLETEXT!: string;
  TEXT!: string;
  constructor(private _translate: TranslateService) {
    this._translate.onLangChange.subscribe((event:TranslationChangeEvent) => {
      const push = event.translations['PUSH'];
      this.setvalues(push);
    });
    this.GetLanguageData();
  }

  private setvalues(push: any) {
    this.TITLETEXT= push['NOPUSHTITLE'];
    this.TEXT= push['NOPUSHTEXT'];
  }
  private GetLanguageData() {
      const push = this._translate.instant('PUSH');
      this.setvalues(push);
  }
}
