import { Injectable } from '@angular/core';

function _window(): Window {
    // return the native window obj
    return window;
}

@Injectable()
/**
 * Represents a reference to the window object.
 */
export class WindowRef {
    /**
     * Gets the native window object.
     * @returns The native window object.
     */
    get nativeWindow(): Window {
        return _window();
    }

    /**
     * Gets a boolean value indicating whether the application is running in standalone mode.
     * Standalone mode refers to when the application is installed on the device and runs independently of a browser.
     * @returns A boolean value indicating whether the application is running in standalone mode.
     */
    get standalone(): boolean {
        let standalone = (this.nativeWindow.navigator as any).standalone;
        if (this.nativeWindow.matchMedia('(display-mode: standalone)').matches) {
            standalone = false;
        }
        return standalone;
    }

    /**
     * Gets the window controls overlay.
     * @returns The window controls overlay.
     */
    get windowControlsOverlay(): any {
        return (this.nativeWindow.navigator as any).windowControlsOverlay;
    }

    /**
     * Gets a value indicating whether the window has a controls overlay.
     * @returns A boolean value indicating whether the window has a controls overlay.
     */
    get HasWindowControlsOverlay(): boolean {
        const overlay = (this.nativeWindow.navigator as any).windowControlsOverlay;
        return overlay == undefined ? false : true;
    }
}