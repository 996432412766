import { Component, OnInit,  OnDestroy, ChangeDetectorRef } from '@angular/core';
import {  concatMap, delay, filter, catchError } from 'rxjs/operators';
import { Subscription, timer, of, Subject, Observable, race } from 'rxjs';
import { Network } from '@ngx-pwa/offline';
import { SignalrService } from 'src/app/core/services/signalR/SignalrService';
import { HubConnectionState } from '@microsoft/signalr';
import { ICounter } from 'src/app/core/model/counter.interface';
import { ISqlChanged } from 'src/app/core/model/sqlchanged.interface';

@Component({
  selector: 'app-heart-beat',
  templateUrl: './heart-beat.component.html',
  styleUrls: ['./heart-beat.component.css']
})
export class HeartBeatComponent implements OnInit, OnDestroy {
  heartbeatLatestStartLabel!: string;
  online:boolean=true;
  heartbeatResponseTime: number;
  heartbeatLatestStart!: number;
  private heartbeatSubscription!: Subscription;
  networkError: boolean=false;
  connected: boolean =false;
  private subject : Subject<number> = new Subject<number>();
  signal$: Observable<number> = this.subject.asObservable();
  private titleSubject : Subject<string> = new Subject<string>();
  title$: Observable<string> = this.titleSubject.asObservable();

  // Heartbeat zones (all in ms)
  heartbeatZones  = [
       50,    // Max response time for 5
       180,    // Max response time for 5
      250    // Max response time for 3
    ];

    // Heartbeat timeout definitions (all in ms)
   heartbeatIntervals = [
      5_000,    // Alerted (1x 1 or start)
      10_000,    // Worried (1x 3)
      20_000    // Relaxed (1x 5)
    ];

    // Heartbeat timeout default
   heartbeatInterval = this.heartbeatIntervals[0];

    // Connection check timeout
   connectionCheckInterval = 20000;

    // Heartbeat log
    heartbeatLog = [
      {responseTime:0,timeout:0,category:0 }];

    // Default signal icon
    heartbeatCategory!: number;
    heartbeatTimeout!: any;
  counter: number=0;

  constructor(private _signalrService:SignalrService,private _network: Network,  private cdRef : ChangeDetectorRef,) {
    this.heartbeatResponseTime = -1;

    this.subject.next(3);

    this._network.onlineChanges.subscribe(res => {
      if(res)
      {
        this.isOnline();
      }
      else
      this.isOffline();

    });
    this._signalrService.ObservableConnected$.subscribe((b:boolean) => {
      if(b)
      this.isOnline();
      this.heartbeatLatestStart = Date.now();
      this.startHeartbeat();

    });

    this._signalrService.hub.subscribe((data: ISqlChanged) => {
      if(data!==null)
      { 
        if( data.counter)
       {
        const counter:ICounter=(data.counter as ICounter);
        this.counter=counter.online;
       }
      }
    });
  }
  ngOnDestroy(): void {
    this.disconnect();
  }

  ngOnInit() {


  }

  isOnline(){
    this.online= true;
  }
  isOffline()
  {
    this.online= false;
  }
  disconnect() {

      this.stopHeartbeat();
      this.networkError = false;

  }
  private setStyle(data: any) {

    // Calculate the response time
    this.heartbeatResponseTime = Date.now() - this.heartbeatLatestStart; // in ms

    // Set the icon
    switch (true) {
      case (this.heartbeatResponseTime < this.heartbeatZones[0]):
        this.heartbeatCategory = 2;
        this.subject.next(5);
        break;
      case ((this.heartbeatResponseTime >= this.heartbeatZones[1]) && (this.heartbeatResponseTime <= this.heartbeatZones[2])):
        this.heartbeatCategory = 1;
        this.subject.next(3);
        break;
      case (this.heartbeatResponseTime > this.heartbeatZones[2]):
        this.heartbeatCategory = 0;
        this.subject.next(1);
        break;
      default:
        this.heartbeatCategory = 0;
        this.subject.next(4);
    }
    // Determine the appropriate timeout
    this.heartbeatInterval = this.heartbeatIntervals[this.heartbeatCategory];

    // Add data to the heartbeat log
    this.heartbeatLog.push({responseTime: this.heartbeatResponseTime, category: this.heartbeatCategory, timeout: this.heartbeatInterval});

    // Limit the number of records to be saved
    if ( this.heartbeatLog.length > 50) {
      this.heartbeatLog.shift();
    }

      // Calculate some stats
      let heartbeatTotalResponseTime   = 0;
      let heartbeatTotalTimeouts       = 0;
      let heartbeatAverageResponseTime = 0;
      let heartbeatCategoryTimeouts    = [0,0,0];
      let heartbeatCategoryPercOfTime  = [0,0,0];

      for ( var i = 0, len=this.heartbeatLog.length; i<len; i++ ) {
        // Totals
        heartbeatTotalResponseTime += this.heartbeatLog[i].responseTime;
        heartbeatTotalTimeouts += this.heartbeatLog[i].timeout;
        // Per category
        heartbeatCategoryTimeouts[this.heartbeatLog[i].category] += this.heartbeatLog[i].timeout;
      }

      for (var i = 0, len=heartbeatCategoryPercOfTime.length; i<len; i++) {
        heartbeatCategoryPercOfTime[i] = Math.round((heartbeatCategoryTimeouts[i] / heartbeatTotalTimeouts) * 100);
      }

      heartbeatAverageResponseTime = Math.round(heartbeatTotalResponseTime / this.heartbeatLog.length);
      this.titleSubject.next(`${this.heartbeatResponseTime} / ${heartbeatAverageResponseTime} (avg) | ${this.counter}` );
  }


  startHeartbeat() {

    // unsubscribe heartbeatSubscription if it is there
    this.stopHeartbeat();
    // Reset netwerk error
    this.networkError = false;

    // Elke 30sec testen
    const heartbeat$ = timer(1_000, 30_000)
      .pipe(
        concatMap(_ => {
          // this.webSocketSubject?.next('ping');
          if(this._signalrService.connection.state === HubConnectionState.Connected)
          {
            this._signalrService.connection.send("Ping","ping");

          return race(
            of('timeout').pipe(delay(3_000)),
            this._signalrService.hubPing.pipe(filter(m => m === 'pong'),catchError(error => of('error'))),
          );
          }
          else
          {
            return of('timeout').pipe(delay(3_000));
          }
        })
      );

    this.heartbeatSubscription = heartbeat$.subscribe(msg => {
      // If response is pong. Websocket connection is still there
      if (msg === 'pong') {
        ///  Set netwerk icon style
        this.setStyle(null);
        this.cdRef.detectChanges();
        // Reset netwerk flag
        this.networkError = false;
        // Wait 10 sec before send a next heatbeat
        of('ping').pipe(
          delay(this.heartbeatInterval)
          ).subscribe(
            t=> {
              if(this._signalrService.connection.state === HubConnectionState.Connected)
              this._signalrService.connection.send("Ping","ping");
              this.heartbeatLatestStart = Date.now();
            }
          )
      } else {
        // msg is not pong throw error flag. Remove websocket subject
        this.networkError = true;
      }
    });
  }

  stopHeartbeat() {
    if (this.heartbeatSubscription) {
      this.heartbeatSubscription.unsubscribe();
    }
  }
  // Set the number of bars
  absoluteRef(id:any) {
    return `url(${window.location.href}#${id})`;
  }
}
