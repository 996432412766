import { Component, OnInit } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import {MenuItem} from 'primeng/api';
import { filter,  Observable, of } from 'rxjs';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { AuthService } from 'src/app/core/services/auth.services';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {
  name:String="";
  lang:String="en";
  public supportedLanguages: any[]=[];
  items: MenuItem[]=[];
  counter$?: Observable<number> = of(0);
  loginDisplay: boolean=false;
  constructor( 
     private _authservices: AuthService,
    private _translate: TranslateService ,
    private _authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
    ) {
      this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
        this.lang = event.lang
      });
   }

  ngOnInit() {
    this.setLoginDisplay();
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => {return (msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS|| msg.eventType === EventType.LOGIN_SUCCESS)}),
    )
    .subscribe((e:any) => {
      if(e!=null && e.payload.account!=null)
      {
        this.loginDisplay =true;
        this.name = e.payload.account.name;
        let n:string[]  = this.name.split(', ');
        if(n[1]==undefined)
         this.name= n[0];
         else
         this.name= n[1] +' '+ n[0];
      }
    });
 

    this.lang = this._translate.currentLang;
    this.items=[
      {
        label: "English",command: () => {this.SetLanguage('en');}
      },
      {
        label: "Dutch",command: () => {this.SetLanguage('nl');}
      }
     ]

        this.supportedLanguages = [
          { display: 'English', value: 'en' },
          { display: 'Dutch',  value: 'nl' },
        ];
      }
      private SetLanguage(l: string) {
        this._translate.use(l);
      }

      isCurrentLang(lang: string) {
        return lang === this._translate.currentLang;
      }

      selectLang(lang: string) {
        this._translate.use(lang);
      }

  login() {
    this._authservices.startAuthentication();
  }

  logout() {
     this._authservices.signout();
  }

  get authenticated(): boolean {
     return this._authservices.isLoggedIn();
  }

  /**
   * Sets the login display based on the number of accounts and updates the name if the login display is true.
   */
  setLoginDisplay() {
    this.loginDisplay = this._authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay)
     this.name = this._authservices.getName();
  }
}
