import { Component, HostListener, EventEmitter, Output, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { AuthService } from 'src/app/core/services/auth.services';
import { NavItem } from 'src/app/core/model/navitem.interface';
import { ApiTokenService } from 'src/app/core/services/apitoken/api-token.service';
import { IApiState } from 'src/app/core/services/apitoken/Iapistate';

@Component({
  selector: 'app-menu-item',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class MenuComponent implements OnInit {
  public  items: NavItem[]=[];
  @Output() toggleChanged: EventEmitter<Boolean> = new EventEmitter<Boolean>();

  title:string='';
  itemMenuManager: NavItem[]=[];
  itemMenuManager1: NavItem[]=[];
  itemMenuManager2: NavItem[]=[];
  itemsmenu: any;
  roles: IApiState={isAdmin:false,isInvoerder:false,isTester:false,hasToken:false,hasStudentIfo:false,isApprover:false, isOpleidingsmanager:false};
  constructor(
    private _authservices: AuthService,
    private cdRef : ChangeDetectorRef,
    private _apiService:ApiTokenService,
    private  _translate: TranslateService) {
      this.itemMenuManager=[
        {header:"Beheer",displayName:"Element", routerLink:'/management/element' , iconName:"folder"},
        {displayName:"Fragment", routerLink:'/management/fragment' , iconName:"folder"},
        {displayName:"OER", routerLink:'/management/onderwijsleidraad' , iconName:"folder"},
        {displayName:"Split element", routerLink:'/management/split' , iconName:"folder"},
        {displayName:"Handleiding", routerLink:'/info/manual' , iconName:"folder"},
        {displayName:"Rapportage", routerLink:'/management/rapportage' , iconName:"folder"},
        {displayName:"OER view(publiek)", routerLink:'/home' , iconName:"folder" ,target:"_blank"}
      ];
      this.itemMenuManager1 =[
        {displayName:"Tegelnaam", routerLink:'/management/tilename' , iconName:"folder"},
        {displayName:"Crebonr", routerLink:'/management/crebo' , iconName:"folder"},
        {displayName:"Links", routerLink:'/management/link' , iconName:"folder"},
        {displayName:"Upload", routerLink:'/management/upload' , iconName:"folder"},
        {displayName:"Webpush", routerLink:'/management/webpush' , iconName:"message"}
    ];
    this.itemMenuManager2 =[
      {displayName:"Rapportage", routerLink:'/management/rapportage' , iconName:"folder"},
      {displayName:"OER + Studiegids", routerLink:'/management/onderwijsleidraad' , iconName:"folder"},
      {displayName:"Handleiding", routerLink:'/info/manual' , iconName:"folder"}
    ];
      this._translate.onLangChange.subscribe((event:TranslationChangeEvent) => {
        const app =  event.translations['APP'];
        this.title= app['TITLE'];
      });
      // Management Menu
      this._apiService.apiRole$.subscribe((role:IApiState) => {
        this.roles=role;
        if(role.isAdmin||role.isInvoerder)
        this.itemMenuManager.forEach(item => {
          this.addItem(item);
        });
        if(role.isApprover || role.isOpleidingsmanager)
        this.itemMenuManager2.forEach(item => {
          this.addItem(item);

        });
        if(role.isAdmin)
        this.itemMenuManager1.forEach(item => {
          this.addItem(item);
        });

      });
      if(this._authservices.isLoggedIn() ) {
        this._apiService.getRoleFromApiToken();
      }
  }
  ngOnInit(): void {
  }

  //Controle omdat bij token exp anders een twee keer het menu wordt toegevoegd
  addItem(item:any) {
    var index = this.items.findIndex(x => x.displayName == item.displayName)
    index ===-1?this.items.push(item):this.test();
  }

  // @HostListener('click')
  // click() {
  //   this.toggle.emit(true);
  // }

  test(): void
  {  }
}
