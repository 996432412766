import { Component, OnInit, AfterViewInit, Input, ChangeDetectorRef, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ApiTokenService } from 'src/app/core/services/apitoken/api-token.service';
import { DestroySubscribers } from 'src/app/core/destroysubscribers';
import { MetaService } from 'src/app/core/services/meta.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { LoaderService } from 'src/app/core/loader/loader.service';
import { combineLatest, Subject, Observable, filter } from 'rxjs';
import { ILoaderState } from 'src/app/core/loader/Iloader';
import { QueryOptions } from 'src/app/core/services/query.options';
import { Sort} from 'src/app/helper/sort-tiles'
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { IOnderwijsleidraad } from 'src/app/core/model/onderwijsleidraad.interface';
import { ILocatie } from 'src/app/core/model/locatie.interface';
import { TileNameService } from 'src/app/core/services/api/tilename.service';
import { OnderwijsleidraadService } from 'src/app/core/services/api/onderwijsleidraad.service';
import { DropdownService } from 'src/app/core/services/api/dropdown.services';
import { ITileName } from 'src/app/core/model/tilename.interface';
import { TileTableComponent } from '../general/keuzedelentabel/tile-table.component';
import { TileComponent } from '../general/tile/tile.component';
import { StudentData } from 'src/app/core/model/student.data';
import { AuthService } from 'src/app/core/services/auth.services';
import { IDropdownOLD } from 'src/app/core/model/dropdown.interface';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { EventMessage, EventType } from '@azure/msal-browser';
import { MsalBroadcastService } from '@azure/msal-angular';
import { IStudent } from 'src/app/core/model/student.interface';
import { StudentService } from 'src/app/core/services/api/student.services';
@Component({
  selector: 'old-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
@DestroySubscribers()
export class StartComponent implements AfterViewInit, OnInit,AfterViewChecked {
  @ViewChild("scrollMe") myScrollContainer!: ElementRef;
  private dataoldSubject$ = new Subject<boolean>();
   dataoldObs$ :Observable<boolean>;
  form: FormGroup;
  Changed: any;
  onderwijsleidraden: IOnderwijsleidraad[]=[];
  isLoading: boolean= true;
  arrayTileNames: ITileName[]=[];
  selectedCrebo: number=0;
  selectedLeerweg: number=0;
  selectedCohort: number=0;
  selectedLocatie: number=0;
  selectedId: number=0;
  heroes$!: Observable<any>;
  paramsCrebo: any
  isLoggedIn: boolean=false;
  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
  }
  creboId:number=23064;
  cohortId:number=1920;
  leerwegId:number=1;
  locatieId:number=4;
  fragementContentId:number[]=[];
  fragementIds:number[]=[];
  fragementen:any[]=[];
  @Input() view!: any[];
  width: number=100 ;
  height: number=100;
  checked: boolean = false;
  show:boolean=false;
  public environment = environment;
  locatieData: ILocatie[]=[];
  locatiedata: ILocatie[]=[];
  creboOptions:IDropdownOLD[]=[];
  leerwegOptions:IDropdownOLD[]=[];
  cohortOptions: IDropdownOLD[]=[];
  locationOptions: IDropdownOLD[]=[];
  HEADER: any;
  TAPTITLE: any;
  RESULT: any;
  NOTHINGFOUND: any;
  TAPSEARCH: any;
  SEARCH: any;
  SELLOCATION: any;
  LOCATION: any;
  SELPATH: any;
  PATH: any;
  CHOOSECREBO: any;
  CHOOSECOHORT: any;
  FOUND: any;
  creboIdStudent: number=0;
  cohortIdStudent: number=0;
  leerwegIdStudent: number=0;
  locatieIdStudent: number=0;
  hasSubscrition: boolean=false;
  isyours: boolean=false;
  VERSION: any;
  hasScrolled: boolean=false;
  startscroll: boolean=false;
  constructor(
    private _router: Router,
    private _tileNameService:TileNameService,
    private _loaderService: LoaderService,
    private _metaService: MetaService,
    private _onderwijsleidraadService:OnderwijsleidraadService,
    public dialogService: DialogService,
    private _apiService:ApiTokenService,
    private _dropDownService:DropdownService,
    private cdRef : ChangeDetectorRef,
    private route: ActivatedRoute,
    private  _translate: TranslateService,
    private _authService: AuthService,
    private _msalBroadcastService: MsalBroadcastService,
    private _studentService:StudentService
    )
    {
      this.dataoldObs$ = this.dataoldSubject$.asObservable();
      this.form = new FormGroup({
        creboName: new FormControl(null, [Validators.required]),
        cohortName: new FormControl(null),
        leerwegName: new FormControl(null),
        locatieName: new FormControl(null),
      });
      this._msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => {return (msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS|| msg.eventType === EventType.LOGIN_SUCCESS)}),
      )
      .subscribe((e:any) => {
        if(e!=null && e.payload.account!=null && this.onderwijsleidraden.length==0)
        {
          this.getUserData(e.payload.account.username)
        }
      });
    }


    /**
     * Lifecycle hook that is called after every check of the component's view.
     * It is called immediately after the `ngAfterViewInit` and `ngAfterContentChecked` lifecycle hooks.
     */
    ngAfterViewChecked(): void {
      if(this.startscroll && !this.hasScrolled)
      {
        this.hasScrolled=true;
        this.startscroll=false;
        this.myScrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
      }
     
    }

    ngOnInit(): void {

      this._translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
        const file = event.translations["DASHBOARD"];
        const app = event.translations["APP"];
        this.setvalues(app, file);
      });
      this.GetLanguageData();
      this.checkoutAccount();

      this._metaService.createCanonicalURL();

      this.route.queryParams.subscribe(params => {
        this.creboId = params['crebo']== undefined? 0:params['crebo'];
        this.leerwegId = params['leerweg'] == undefined? 0:params['leerweg'];
        this.cohortId = params['cohort']== undefined? 0:params['cohort'];
        this.locatieId = params['locatieId']== undefined? 0:params['locatieId'];
        this.paramsCrebo = params['crebo'];
        if(typeof +this.paramsCrebo == 'number' && this.paramsCrebo>0)
        this.getOLDData(false);

      });
      this._dropDownService.locatiedataObs$.subscribe( (ld)=>{
        this.locatiedata= ld;
      });
      this._dropDownService.datacreboObs$.subscribe(
        (c) =>{
          this.creboOptions=c
        }
      );
      this._dropDownService.dataleerwegObs$.subscribe(
        (options) =>{
          this.leerwegOptions=options
        }
      );
      this._dropDownService.dataperiodeObs$.subscribe(
        (options) =>{
          this.cohortOptions=options

        }
      );
      this._dropDownService.datalocatieObs$.subscribe(
        (options) =>{
          this.locationOptions=options
        }
      );
      // this.dataoldObs$.subscribe((d:boolean) => {
      //   if(d)
      //   {

      //     this.show = d;
      //     this.cdRef.detectChanges();

      //   }
      // });
       this._dropDownService.loaderState$.subscribe((state: ILoaderState) => {
          if (state.show != this.isLoading) {
            this.isLoading = state.show;
            this.cdRef.detectChanges();
          }
      });
      combineLatest(this._dropDownService.loaderState$,
        this.dataoldObs$,
       (dplstate, old) => ({dplstate, old}))
      .subscribe(pair => {
        if(!pair.dplstate.show && pair.old)
        {
          this.form.patchValue({
            creboName: this.creboId,
            leerwegName: this.leerwegId,
            cohortName: this.cohortId,
            locatieName: this.locatieId
          });
        }
     });
     if(this.isLoggedIn)
     {
      this.tileNamesOphalen();
      this._dropDownService.getCreboData();
      this._dropDownService.getLeerwegData();
      this._dropDownService.getPeriodeData();
      this._dropDownService.getLocatieData();
     }

      this.form.get('creboName')?.valueChanges.subscribe(
        val => {
          if(val==null || typeof val !== 'number')
          {
            if(this.creboIdStudent!==0)
            {
              this.creboName?.setErrors(null);
              this.getUserData()
            }
            return;
          }
          if(this.selectedCrebo!=+val)
          {
            if(this.selectedCrebo!=0)
             this.resetOnderwijsleidraad();
            this.selectedCrebo = val;
          }
        }
      );
      this.form.get('cohortName')?.valueChanges.subscribe(
        val => {

          if(val==null || typeof val !== 'number')
            return;
         if(this.selectedCohort!=+val)
         {
          if(this.selectedCohort!=0)
           this.resetOnderwijsleidraad();
          this.selectedCohort = val;
         }
        }
      );
      this.form.get('leerwegName')?.valueChanges.subscribe(
        val => {
         if(val==null || typeof val !== 'number')
          return;

          if(this.selectedLeerweg!=+val)
          {
            if(this.selectedLeerweg!=0)
              this.resetOnderwijsleidraad();
            this.selectedLeerweg = val;
          }
        }
      );
      this.form.get('locatieName')?.valueChanges.subscribe(
        val => {
          if(val==null || typeof val !== 'number')
          return;
          if(this.selectedLocatie!==+val)
          {
            if(this.selectedLocatie!=0)
              this.resetOnderwijsleidraad();
              this.selectedLocatie = val;
            }
        }
      );
    }

  checkoutAccount():void {
       this.isLoggedIn = this._authService.isLoggedIn();
      // if(!this.isLoggedIn)
      // this._router.navigate(['login']);
  }

  private resetOnderwijsleidraad():void {
    if (this.onderwijsleidraden.length > 0)
      {this.onderwijsleidraden = [];
        this.show = true;
      }
  }

  changed():void
  {
    const c= this.checked?'true':'false';
    localStorage.setItem('newtiles',c);
  }

  onSubmit():void {
    if (this.form.pending) {
      this.form.statusChanges.subscribe(status => {
        if (status === 'VALID') {
          this.search();
        }
      });
    } else {
      if (this.form.valid) {
        this.search();
      }
    }
  }


  /**
   * Performs a search based on the form input values and retrieves data.
   */
  public search():void
  {
    const formModel = this.form.value;
    this.creboId =isNaN(+formModel.creboName)?0:+formModel.creboName;
    this.leerwegId =isNaN(+formModel.leerwegName)?0:+formModel.leerwegName;
    this.cohortId =isNaN(+formModel.cohortName)?0:+formModel.cohortName;
    this.locatieId =isNaN(+formModel.locatieName)?0:+formModel.locatieName;
    this.getOLDData(false);
  }

  /**
   * Retrieves the tile names from the server and assigns them to the `arrayTileNames` property.
   */
  private tileNamesOphalen(): void {
    let qo:QueryOptions= new QueryOptions();
    qo.fields ='name,code,color'
    this._tileNameService.list(qo).subscribe({
      next: (response: ITileName[]) => {
        this.arrayTileNames = response;
      },
      error: () => {}
    });
  }
  private getOLDData(isStudent:boolean) {
    this.hasScrolled=false;
    this._onderwijsleidraadService.readold(this.creboId, this.cohortId, this.leerwegId, this.locatieId).subscribe((result: IOnderwijsleidraad[]) => {
      if (result != null) {
        this.onderwijsleidraden = [];
        if (result !== null) {
          if(result.length==1 && isStudent &&
            this.creboId==this.creboIdStudent &&
            this.cohortId==this.cohortIdStudent &&
            this.leerwegId==this.leerwegIdStudent &&
             this.locatieId==this.locatieIdStudent)
          {
            result[0].name ="Jouw Onderwijsleidraad: " + result[0].name;
            this.isyours= true;
          }
          else{
            this.isyours= false;
          }
          // Sorteren van Tiles op door dienst onderwijs vaste volgorde
          Sort.Tiles(result);
          result.forEach((r: IOnderwijsleidraad) => {
            if(r.tilesarray!==undefined)
            {
              r.tilesarray.forEach((tile: any) => {
                const tilename: string = this.getTileName(tile.fragmenttype, 1);
                const color: string = this.arrayTileNames.filter((l) => {
                  return l.code == tile.fragmenttype;
                })[0]['color'];
                tile['color'] = color;
                tile['name'] = tilename;
              });
            }
          });    



         this.onderwijsleidraden = result;
          this.startscroll=true;
        }
      }
      else {
        this.dataoldSubject$.next(false);
        this.onderwijsleidraden = [];
      }
    });
  }

  get creboName() {
    return this.form.get('creboName');
  }
  get cohortName() {
    return this.form.get('cohortName');
  }
  get leerwegName() {
    return this.form.get('leerwegName');
  }
  get locatieName() {
    return this.form.get('locatieName');
  }


  /**
   * Retrieves the tile name based on the fragment type and type.
   * @param fragementType - The fragment type.
   * @param type - The type.
   * @returns The tile name.
   */
  getTileName(fragementType: any, type: any) {
    if (this.arrayTileNames == null || this.arrayTileNames == undefined)
      return '';
    let ft = this.arrayTileNames.filter((l) => {
      return l.code == fragementType.toString();
    })[0]['name'];
    if (type == 1) ft = ft.replace('-', '\n').replace('-', '\n');
    return ft;
  }


  /**
   * Displays a tile based on the provided data.
   * @param data - The data object containing information about the tile.
   */
  showTile(data:any):void {
    let name:string|undefined= this.getTileName(data.fragmenttype,0);
    const t = data.FragmentType==80?TileTableComponent:TileComponent;
    const ref = this.dialogService.open(t, {
         data: {
            id: data.id,
            fragmentName:name,
            oer:false
        },
        dismissableMask:true,
        header: name,
        width: '90%'
    });
  }

  ngAfterViewInit(): void {
    if(localStorage.getItem('newtiles')!= undefined)
    {
    let c = (localStorage.getItem('newtiles')==="true");
     this.checked = c;
    }
  }

  private getUserData(username?:string) {
    // Student gegevens ophalen uit localstorage opgeslagen na inlog
       let userName = this._authService.getNovaId();

       if(username!==undefined && userName!='')
       {
        userName = username.indexOf('@')!==-1 ? username.split('@')[0]:username;
       }
       var reg = new RegExp('^[0-9]+$');
       if(!reg.test(userName))
       {
        return;
       }
       if (localStorage.getItem(userName) != undefined) {
        if(localStorage.getItem(userName)!=null)
        {
          const store:any= localStorage.getItem(userName)
          let c = StudentData.fromJSON(JSON.parse(store));

          if (c.crebo !== undefined && this.paramsCrebo == undefined) {
            this.creboId = c.crebo;
            this.creboIdStudent = c.crebo;
            this.leerwegId = c.id_leerweg;
            this.leerwegIdStudent = c.id_leerweg;
            if(this.locatiedata.length>0)
             {
              const parentid = this.locatiedata.find(x => +x.code == c.id_location);
              if(parentid!=undefined)
                this.locatieId = parentid.id;
              else
              this.locatieId=0;
              this.locatieIdStudent=this.locatieId;
             }
            this.cohortId = +`${+c.period}${+(+c.period + 1)}`;
            this.cohortIdStudent = this.cohortId;
            // Onderwijsleidraad gegevens ophalen voor student
            this.getOLDData(true);
          }
        }

    }
    else{
      const stamnr= +userName;
      let o: QueryOptions = new QueryOptions();
      o.stamnr = stamnr;
      this._studentService.list(o).subscribe((student: IStudent[]) => {
        if(student!== undefined)
        {
          let s:IStudent= {
            id_leerweg:student[0].id_leerweg,
            id_location:student[0].id_location,
            crebo:student[0].crebo,
            period:student[0].period,
            id:stamnr
          };
          localStorage.setItem(userName,JSON.stringify(s));
          window.location.reload();
        }
      });
    }
  }
  private setvalues(app:any, search: any) {
    this.SEARCH = app['SEARCH'];
    this.CHOOSECREBO = app['CHOOSECREBO'];
    this.CHOOSECOHORT = app['CHOOSECOHORT'];
    this.FOUND = app['FOUND'];
    this.VERSION = app['VERSION'];
    this.HEADER = search['HEADER'];
    this.RESULT = search['RESULT'];
    this.TAPTITLE = search['TAPTITLE'];
    this.TAPSEARCH = search['TAPSEARCH'];
    this.LOCATION = search['LOCATION'];
    this.SELLOCATION = search['SELLOCATION'];
    this.PATH = search['PATH'];
    this.SELPATH = search['SELPATH'];
    this.NOTHINGFOUND = search['NOTHINGFOUND'];
  }

  private GetLanguageData() {
    const search = this._translate.instant('DASHBOARD');
    const app = this._translate.instant('APP');
    this.setvalues(app, search);
  }
}
