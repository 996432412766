import { NgModule , Optional, SkipSelf} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ConfigService } from './config.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { AlertService } from './services/alert.service';
import { PushService } from './push.service';
import { FragmentService } from './services/api/fragment.service';
import { LocatieService } from './services/api/locatie.service';
import { CreboService } from './services/api/crebo.services';
import { PeriodeService } from './services/api/cohort.services';
import { LeerwegService } from './services/api/leerweg.services';
import { StudentService } from './services/api/student.services';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './loader/loader.service';
import { TileNameService } from './services/api/tilename.service';
import { OnderwijsleidraadService } from './services/api/onderwijsleidraad.service';
import { SharedModule } from '../shared/shared.module';
import { LanguageService } from './services/language.service';
import { WebPushNotificationService } from './services/api/webpush-notification.services';
import { WebPushService } from './services/api/webpush-subscription.services';
import { FileService } from './services/file.service';
import { AuthService } from './services/auth.services';
import { OERService } from './services/api/oer.service';
import { RapportageService } from './services/api/rapportage.service';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  exports: [
    LoaderComponent,
  ],
  declarations: [
    LoaderComponent
    ],
  providers: [
   LanguageService,
    ConfigService,
    AlertService,
     LoaderService,
    TileNameService,
    OnderwijsleidraadService,
    FragmentService,
    CreboService,
    PeriodeService,
    LeerwegService,
    LocatieService,
    RapportageService,
    StudentService,
    OERService,
    PushService,
    WebPushNotificationService,
    WebPushService,
    FileService,
    AuthService,
  ]
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
   throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
 }
